// import React from "react";

// export default function Cta() {
//   return (
//     <section>
//       <div className="max-w-6xl mx-auto px-4 sm:px-6">
//         <div className="pb-12 md:pb-20">
//           {/* CTA box */}
//           <div
//             className="bg-green-600 rounded py-10 px-8 md:py-16 md:px-12 shadow-2xl"
//             // data-aos="zoom-y-out"
//           >
//             <div className="flex flex-col lg:flex-row justify-between items-center">
//               {/* CTA content */}
//               <div className="mb-6 lg:mr-16 lg:mb-0 text-center lg:text-left">
//                 <h3 className="text-white text-3xl mb-2 font-bold">
//                   {/* Ready to validate your business idea ? */}
//                   {/* Ready to Validate Your First Idea? */}
//                   Start Your Validation Journey Now
//                 </h3>
//                 {/* <p className="text-white text-lg opacity-75">
//                   Enjoy our free preview before you commit.
//                 </p> */}
//               </div>

//               {/* CTA button */}
//               <div>
//                 <a
//                   className="btn  bg-gradient-to-r from-blue-100 to-white font-semibold animate-pulse"
//                   href="#pricing"
//                 >
//                   {/* Get Started For Free */}
//                   {/* Start Your Free Trial */}
//                   {/* Start Your Journey Today */}
//                   Start Now
//                 </a>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }

import React from "react";

export default function Cta() {
  return (
    <section className="bg-gradient-to-r from-green-500 to-green-600 py-16 md:py-20">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="bg-white bg-opacity-10 rounded-2xl py-10 px-8 md:py-16 md:px-12 shadow-2xl backdrop-blur-sm">
          <div className="flex flex-col lg:flex-row justify-between items-center">
            <div className="mb-6 lg:mr-16 lg:mb-0 text-center lg:text-left">
              <h3 className="text-white text-3xl md:text-4xl font-bold leading-tight mb-2">Start Your Validation Journey Now</h3>
              <p className="text-green-100 text-lg">Transform your idea into a thriving business with AI-powered insights.</p>
            </div>
            <div>
              <a
                href="/signup/"
                className="inline-block px-8 py-4 bg-white text-green-600 font-semibold rounded-full shadow-lg hover:bg-green-50 transition-colors duration-300 ease-in-out transform hover:-translate-y-1"
              >
                Get Started
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
