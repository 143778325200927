// import React, { useState, useEffect } from "react";

// import MobileMenu from "../components/Mobile-menu";
// import { Link } from "react-router-dom";
// import LogoutButton from "./LogoutButton";
// import Logo from "../images/thumbnail.png";
// import { NavLink } from "react-router-dom"; // Import NavLink

// export default function DashboardHeader() {
//   const [user, setUser] = useState(null);
//   const [top, setTop] = useState(true);

//   useEffect(() => {
//     const token = localStorage.getItem("access");

//     if (!token) {
//       window.location.href = "/signin/";
//     } else {
//       // Check token expiry if using JWT
//       try {
//         const decodedToken = JSON.parse(atob(token.split(".")[1]));
//         const currentTimestamp = Math.floor(Date.now() / 1000); // current time in seconds since epoch

//         if (decodedToken.exp && decodedToken.exp < currentTimestamp) {
//           localStorage.removeItem("access"); // remove the expired token
//           window.location.href = "/signin/";
//         } else {
//           //   setUser(true);
//           setUser(true);
//         }
//       } catch (error) {
//         // Failed to decode token or other error
//         console.error("Failed to decode the token:", error);
//         window.location.href = "/signin/";
//       }
//     }
//   }, []);

//   // Function to render link with NavLink and apply active styles
//   const renderLink = (to, text) => (
//     <NavLink
//       to={to}
//       className={({ isActive }) =>
//         `bg-green-200 hover:bg-green-300 text-green-800 hover:text-green-900 px-4 py-2 rounded-full flex items-center transition duration-150 ease-in-out shadow ${
//           isActive ? "ring ring-green-500 ring-opacity-50" : ""
//         }`
//       }
//     >
//       {text}
//     </NavLink>
//   );

//   return (
//     <header
//       className={`top w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out ${
//         !top ? "bg-white backdrop-blur-sm shadow-lg" : ""
//       }`}
//     >
//       <div className="max-w-7xl mx-auto px-5 sm:px-6">
//         <div className="flex items-center justify-between h-16 md:h-20">
//           {/* Site branding */}
//           {/* Site branding */}
//           <div className="shrink-0 mr-4">
//             <NavLink
//               to="/dashboard/"
//               className="flex items-center text-2xl font-bold text-gray-800"
//             >
//               <img src={Logo} alt="Logo" className="h-10 mr-2" />
//               <span className="font-bold text-green-600 h-8">FeedbackbyAI</span>
//             </NavLink>
//           </div>

//           {/* Desktop navigation */}
//           <nav className="hidden md:flex md:grow">
//             {/* Desktop menu links */}
//             <ul className="flex grow justify-end flex-wrap items-center">
//               <li className="mx-2">
//                 {renderLink("/feedback/", "AI Character Feedback")}
//               </li>

//               <li className="mx-2">
//                 {renderLink("/dashboard/", "100+ Page Report")}
//               </li>
//               <li className="mx-2">
//                 {renderLink("/networking/", "Business Exchange")}
//               </li>

//               {/* <li className="mx-2">
//                 {renderLink("/feedback-by-astrology/", "Corporate Astrology")}
//               </li> */}
//               {/* <li className="mx-2">
//                 {renderLink("/it-development/", "IT Development")}
//               </li> */}
//               <li className="mx-2">
//                 {renderLink("/credits/", "Credits & Recharge")}
//               </li>
//               <li className="mx-2">
//                 <a
//                   href="mailto:business@feedbackbyai.com"
//                   className="bg-green-200 hover:bg-green-300 text-green-800 hover:text-green-900 px-4 py-2 rounded-full flex items-center transition duration-150 ease-in-out shadow"
//                 >
//                   Contact Us
//                 </a>
//               </li>
//             </ul>

//             {/* Desktop sign in links */}
//             <ul className="flex grow justify-end flex-wrap items-center">
//               <li>
//                 <a
//                   href="/signin/"
//                   className="btn-sm text-gray-200 bg-emerald-600 hover:bg-emerald-700 ml-3"
//                 >
//                   <span>
//                     {user && (
//                       <div className="text-right pr-4">
//                         <LogoutButton setUser={setUser} />
//                       </div>
//                     )}
//                   </span>
//                 </a>
//               </li>
//             </ul>
//           </nav>

//           <MobileMenu user={user} setUser={setUser} />
//         </div>
//       </div>
//     </header>
//   );
// }

import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";

import { Menu, X, ChevronDown } from "lucide-react";
import Logo from "../images/thumbnail.png";
import LogoutButton from "./LogoutButton";
import { motion, AnimatePresence } from "framer-motion";

const NavItem = ({ to, children }) => (
  <NavLink
    to={to}
    className={({ isActive }) =>
      `px-4 py-2 rounded-full text-sm font-medium transition-all duration-200 ${
        isActive ? "bg-green-100 text-green-800" : "text-gray-600 hover:bg-green-50 hover:text-green-700"
      }`
    }
  >
    {children}
  </NavLink>
);

const MobileNavItem = ({ to, children, onClick }) => (
  <NavLink
    to={to}
    onClick={onClick}
    className={({ isActive }) =>
      `block px-4 py-2 text-base font-medium transition-colors duration-200 ${
        isActive ? "bg-green-100 text-green-800" : "text-gray-600 hover:bg-green-50 hover:text-green-700"
      }`
    }
  >
    {children}
  </NavLink>
);

export default function DashboardHeader() {
  const [user, setUser] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("access");

    if (!token) {
      window.location.href = "/signin/";
    } else {
      try {
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        const currentTimestamp = Math.floor(Date.now() / 1000);

        if (decodedToken.exp && decodedToken.exp < currentTimestamp) {
          localStorage.removeItem("access");
          window.location.href = "/signin/";
        } else {
          setUser(true);
        }
      } catch (error) {
        console.error("Failed to decode the token:", error);
        window.location.href = "/signin/";
      }
    }
  }, []);

  return (
    <header className="bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-4 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <NavLink to="/dashboard/" className="flex items-center">
              <img className="h-8 w-auto sm:h-10" src={Logo} alt="FeedbackbyAI" />
              <span className="ml-2 text-xl font-bold text-green-600">FeedbackbyAI</span>
            </NavLink>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <button
              type="button"
              className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500"
              onClick={() => setIsOpen(!isOpen)}
            >
              <span className="sr-only">Open menu</span>
              <Menu className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <nav className="hidden md:flex space-x-10">
            <NavItem to="/feedback/">AI Character Feedback</NavItem>
            <NavItem to="/dashboard/">100+ Page Report</NavItem>
            <NavItem to="/previewx/">Sample Report</NavItem>
            {/* <NavItem to="/networking/">Business Exchange</NavItem> */}
            <NavItem to="/credits/">Credits & Recharge</NavItem>
          </nav>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            <a
              href="mailto:business@feedbackbyai.com"
              className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-full shadow-sm text-base font-medium text-white bg-green-600 hover:bg-green-700"
            >
              Contact Us
            </a>
            {user && (
              <div className="ml-8">
                <LogoutButton setUser={setUser} />
              </div>
            )}
          </div>
        </div>
      </div>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
          >
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div>
                    <img className="h-8 w-auto" src={Logo} alt="FeedbackbyAI" />
                  </div>
                  <div className="-mr-2">
                    <button
                      type="button"
                      className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500"
                      onClick={() => setIsOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <X className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </div>
                <div className="mt-6">
                  <nav className="grid gap-y-8">
                    <MobileNavItem to="/feedback/" onClick={() => setIsOpen(false)}>
                      AI Character Feedback
                    </MobileNavItem>
                    <MobileNavItem to="/dashboard/" onClick={() => setIsOpen(false)}>
                      100+ Page Report
                    </MobileNavItem>
                    <MobileNavItem to="/previewx/" onClick={() => setIsOpen(false)}>
                      Sample Report
                    </MobileNavItem>
                    {/* <MobileNavItem to="/networking/" onClick={() => setIsOpen(false)}>
                      Business Exchange
                    </MobileNavItem> */}
                    <MobileNavItem to="/credits/" onClick={() => setIsOpen(false)}>
                      Credits & Recharge
                    </MobileNavItem>
                  </nav>
                </div>
              </div>
              <div className="py-6 px-5 space-y-6">
                <div>
                  <a
                    href="mailto:business@feedbackbyai.com"
                    className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-green-600 hover:bg-green-700"
                  >
                    Contact Us
                  </a>
                  {user && (
                    <div className="mt-6">
                      <LogoutButton setUser={setUser} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
}
