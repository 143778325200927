import React, { useState, useEffect } from "react";
import axios from "axios";
import api from "./axiosConfig";
import { PhotographIcon, MailIcon } from "@heroicons/react/outline";
import DashboardHeader from "./DashboardHeader";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { FaPhoneAlt, FaMoneyBillAlt, FaComments } from "react-icons/fa";
import image1 from "../images/example-1.png";
import image2 from "../images/example.png";
import image3 from "../images/f3.png";
import HowItWorks from "./HowItWorks";
import {
  FaIndustry,
  FaMapMarkerAlt,
  FaExternalLinkAlt,
  FaCalendarAlt,
  FaUsers,
  FaChartLine,
  FaHandsHelping,
} from "react-icons/fa";

const images = [image1, image2, image3];

const ImageCarousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goLeft = () => {
    const nextIndex = currentIndex === 0 ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(nextIndex);
  };

  const goRight = () => {
    const nextIndex = currentIndex === images.length - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(nextIndex);
  };

  return (
    <div className="relative">
      <div className="absolute inset-0 flex items-center justify-between">
        <button
          onClick={goLeft}
          className="p-4 bg-black bg-opacity-50 text-white hover:bg-opacity-75"
        >
          &#8592;
        </button>
        <button
          onClick={goRight}
          className="p-4 bg-black bg-opacity-50 text-white hover:bg-opacity-75"
        >
          &#8594;
        </button>
      </div>
      {images.map((image, index) => (
        <div
          key={image}
          className={`w-full h-96 bg-cover bg-center ${
            index === currentIndex ? "block" : "hidden"
          }`}
          style={{ backgroundImage: `url(${image})` }}
        ></div>
      ))}
    </div>
  );
};

//   const images = [
//     'images/photo1.jpg',
//     'images/photo2.jpg',
//     'images/photo3.jpg',
//     'images/photo4.jpg',
//     'images/photo5.jpg',
//   ];

const toSnakeCase = (str) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

const convertKeysToSnakeCase = (obj) => {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    newObj[toSnakeCase(key)] = obj[key];
  });
  return newObj;
};

const toCamelCase = (str) =>
  str.replace(/_([a-z])/g, (g) => g[1].toUpperCase());

const convertKeysToCamelCase = (obj) => {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    newObj[toCamelCase(key)] = obj[key];
  });
  return newObj;
};

const styles = {
  fontFamily: "Inter, sans-serif",
};

const BusinessProfile = () => {
  const [profile, setProfile] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({
    businessName: "",
    industry: "",
    size: "",
    location: "",
    aboutUs: "",
    servicesOffered: "",
    lookingFor: "",
    keyProjects: "",
    awardsAndRecognitions: "",
    contactEmail: "",
    contactPhone: "",
    linkedInUrl: "",
    websiteUrl: "",
  });

  // Fetch profile data on component mount
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await api.get("/business_profiles/my_profile");
        if (response.data) {
          const profileData = convertKeysToCamelCase(response.data);
          console.log("profile data is ---->", response.data);

          setProfile(profileData);
          setFormData(profileData);
        } else {
          setEditMode(true); // No profile found, switch to edit mode directly
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
        setEditMode(true); // On error, assume no profile and switch to edit mode
      }
    };

    fetchProfile();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    if (e.target.files.length) {
      // Assuming you're storing the uploaded image in formData as 'profilePicture'
      // Create a reader to convert image file to base64 string
      const reader = new FileReader();
      reader.onload = function (upload) {
        setFormData((prevState) => ({
          ...prevState,
          profilePicture: upload.target.result,
        }));
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  //   const handleSubmit = async (e) => {
  //     e.preventDefault();

  //     try {
  //       const dataToSend = convertKeysToSnakeCase(formData);
  //       let response;
  //       if (profile) {
  //         response = await api.put("/business_profiles/my_profile/", dataToSend);
  //       } else {
  //         response = await api.post("/business_profiles/my_profile/", dataToSend);
  //       }

  //       // Assuming your backend returns the updated profile in the response
  //       // Convert the keys back to camelCase before updating the state
  //       const updatedProfile = convertKeysToCamelCase(response.data);

  //       setProfile(updatedProfile); // Update profile with the returned data
  //       setFormData(updatedProfile); // Also update formData to reflect the changes
  //       setEditMode(false);
  //       alert("Profile saved successfully!");
  //     } catch (error) {
  //       console.error("Failed to save profile:", error);
  //       alert("Failed to save profile.");
  //     }
  //   };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create FormData object
    const formDataObj = new FormData();

    // Append file if it exists
    if (e.target.profilePicture.files[0]) {
      formDataObj.append("profile_picture", e.target.profilePicture.files[0]);
    }

    // Append other fields, converting keys to snake case as needed
    Object.keys(formData).forEach((key) => {
      if (key !== "profilePicture") {
        // Exclude the profile picture since it's handled separately
        formDataObj.append(toSnakeCase(key), formData[key]);
      }
    });

    try {
      let response;
      if (profile) {
        response = await api.put(
          "/business_profiles/my_profile/",
          formDataObj,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } else {
        response = await api.post(
          "/business_profiles/my_profile/",
          formDataObj,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }

      // Assuming your backend returns the updated profile in the response, including the file's URL
      const updatedProfile = convertKeysToCamelCase(response.data);

      setProfile(updatedProfile); // Update profile with the returned data
      setFormData(updatedProfile); // Also update formData to reflect the changes
      setEditMode(false);
      alert("Profile saved successfully!");
    } catch (error) {
      console.error("Failed to save profile:", error);
      alert("Failed to save profile.");
    }
  };

  return (
    <div
      style={styles}
      className="font-inter antialiased bg-white text-gray-900 tracking-tight "
    >
      <DashboardHeader />
      <div className="grow container mx-auto p-4 min-h-screen px-4 py-8">
        <div className="max-w-5xl mx-auto rounded-lg overflow-hidden">
          {editMode ? (
            <form onSubmit={handleSubmit} className="space-y-6 p-4">
              {/* Editable form fields for each profile attribute */}
              {/* Profile Picture Upload */}

              <div>
                <label
                  htmlFor="profilePicture"
                  className="block text-sm font-medium text-gray-700"
                >
                  Profile Picture
                </label>
                <input
                  type="file"
                  name="profilePicture"
                  id="profilePicture"
                  onChange={handleImageChange}
                  className="mt-1 block w-full text-sm text-gray-500
                  file:mr-4 file:py-2 file:px-4
                  file:rounded-full file:border-0
                  file:text-sm file:font-semibold
                  file:bg-blue-50 file:text-blue-700
                  hover:file:bg-blue-100"
                />
              </div>

              {/* Business Name */}
              <div>
                <label
                  htmlFor="businessName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Business Name
                </label>
                <input
                  type="text"
                  name="businessName"
                  id="businessName"
                  value={formData.businessName}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>

              {/* Industry */}
              <div>
                <label
                  htmlFor="industry"
                  className="block text-sm font-medium text-gray-700"
                >
                  Industry
                </label>
                <input
                  type="text"
                  name="industry"
                  id="industry"
                  value={formData.industry}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>

              {/* About Us */}
              <div>
                <label
                  htmlFor="aboutUs"
                  className="block text-sm font-medium text-gray-700"
                >
                  About Us
                </label>
                <textarea
                  name="aboutUs"
                  id="aboutUs"
                  rows="3"
                  value={formData.aboutUs}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                ></textarea>
              </div>

              {/* Services Offered */}
              <div>
                <label
                  htmlFor="servicesOffered"
                  className="block text-sm font-medium text-gray-700"
                >
                  Services Offered
                </label>
                <textarea
                  name="servicesOffered"
                  id="servicesOffered"
                  rows="3"
                  value={formData.servicesOffered}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                ></textarea>
              </div>

              {/* Looking For */}
              <div>
                <label
                  htmlFor="lookingFor"
                  className="block text-sm font-medium text-gray-700"
                >
                  Looking For
                </label>
                <textarea
                  name="lookingFor"
                  id="lookingFor"
                  rows="3"
                  value={formData.lookingFor}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                ></textarea>
              </div>

              {/* Key Projects */}
              {/* <div>
                <label
                  htmlFor="keyProjects"
                  className="block text-sm font-medium text-gray-700"
                >
                  Key Projects/Portfolio
                </label>
                <textarea
                  name="keyProjects"
                  id="keyProjects"
                  rows="3"
                  value={formData.keyProjects}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                ></textarea>
              </div> */}

              {/* Awards and Recognitions */}
              <div>
                <label
                  htmlFor="awardsAndRecognitions"
                  className="block text-sm font-medium text-gray-700"
                >
                  Awards and Recognitions
                </label>
                <textarea
                  name="awardsAndRecognitions"
                  id="awardsAndRecognitions"
                  rows="3"
                  value={formData.awardsAndRecognitions}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                ></textarea>
              </div>

              {/* Contact Information */}
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <div>
                  <label
                    htmlFor="contactEmail"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    name="contactEmail"
                    id="contactEmail"
                    value={formData.contactEmail}
                    onChange={handleChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
                <div>
                  <label
                    htmlFor="contactPhone"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Phone
                  </label>
                  <input
                    type="text"
                    name="contactPhone"
                    id="contactPhone"
                    value={formData.contactPhone}
                    onChange={handleChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              {/* LinkedIn URL */}
              <div>
                <label
                  htmlFor="linkedInUrl"
                  className="block text-sm font-medium text-gray-700"
                >
                  LinkedIn URL
                </label>
                <input
                  type="url"
                  name="linkedInUrl"
                  id="linkedInUrl"
                  value={formData.linkedInUrl}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>

              <div className="flex justify-between items-center">
                <button
                  type="submit"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Save Profile
                </button>
                <button
                  onClick={() => setEditMode(false)}
                  className="mt-2 text-sm text-blue-500 hover:text-blue-700"
                >
                  Cancel
                </button>
              </div>
            </form>
          ) : // Profile display mode
          profile ? (
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl">
              {/* Company Profile Section */}
              <div className="bg-white p-8 rounded-lg shadow-lg mt-8 flex flex-col items-center text-center">
                <div className="w-24 h-24 rounded-full overflow-hidden border-4 border-green-600 mb-4">
                  <img
                    src={profile.profilePicture || "/default-logo.png"}
                    alt="Company Logo"
                    className="w-full h-full object-cover"
                  />
                </div>
                <h1 className="text-3xl font-bold text-gray-900 mb-2">
                  {profile.businessName}
                </h1>
                <p className="text-sm text-gray-600 mb-4">
                  {profile.industry} &bull; {profile.location} &bull; Founded
                  Nov 2023
                </p>
                <button className="bg-green-600 hover:bg-green-700 text-white font-semibold py-3 px-8 rounded-full transition duration-300 ease-in-out focus:outline-none transform hover:scale-105 mb-4">
                  Contact Us For
                </button>
                <div className="grid grid-cols-3 gap-4">
                  {/* Use Case 1 */}
                  <div className="bg-green-100 rounded-lg p-4 shadow-md text-center">
                    <FaChartLine className="text-4xl text-green-600 mb-3 mx-auto" />
                    <h3 className="text-lg font-semibold text-gray-800 mb-2">
                      Understanding Cinema Newspaper Advertisements In Bihar
                    </h3>
                  </div>
                  {/* Use Case 2 */}
                  <div className="bg-green-100 rounded-lg p-4 shadow-md text-center">
                    <FaUsers className="text-4xl text-green-600 mb-3 mx-auto" />
                    <h3 className="text-lg font-semibold text-gray-800 mb-2">
                      Book Our Services
                    </h3>
                  </div>
                  {/* Use Case 3 */}
                  <div className="bg-green-100 rounded-lg p-4 shadow-md text-center">
                    <FaHandsHelping className="text-4xl text-green-600 mb-3 mx-auto" />
                    <h3 className="text-lg font-semibold text-gray-800 mb-2">
                      Partnership Opportunities
                    </h3>
                  </div>
                </div>
              </div>

              {/* Looking For and Services Section */}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-8">
                <div className="bg-green-100 rounded-lg p-4 shadow-md">
                  <h2 className="text-lg font-semibold text-gray-800 mb-3">
                    Looking For
                  </h2>
                  <ul className="list-disc list-inside text-gray-800">
                    {[
                      "Cinema Distributors",
                      "Film Producers",
                      "Advertisements Agencies",
                    ].map((item, index) => (
                      <li key={index} className="mb-2">
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="bg-green-100 rounded-lg p-4 shadow-md">
                  <h2 className="text-lg font-semibold text-gray-800 mb-3">
                    Can Provide Services In
                  </h2>
                  <ul className="list-disc list-inside text-gray-800">
                    {[
                      "Instant Business Idea Validator",
                      "AI Business Plans",
                      "AI Character Feedback",
                      // Add more services here
                    ].map((item, index) => (
                      <li key={index} className="mb-2">
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              {/* About Us Section */}
              <div className="bg-gray-100 p-6 rounded-lg shadow-lg mt-8">
                <h2 className="text-3xl font-bold mb-4">About Us</h2>
                <p className="text-base text-gray-800">{profile.aboutUs}</p>
              </div>

              {/* Use Cases Section */}
              <div className="bg-gradient-to-br from-gray-200 to-gray-300 p-6 rounded-lg shadow-lg mt-8">
                <h2 className="text-3xl font-bold mb-4">Use Cases</h2>
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
                  {/* Use Case 1 */}
                  <div className="bg-white rounded-lg p-6 shadow-md flex flex-col justify-between items-center transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl">
                    <FaChartLine className="text-5xl text-green-600 mb-4" />
                    <h3 className="text-xl font-semibold text-gray-900 mb-2">
                      Market Research
                    </h3>
                    <p className="text-base text-gray-600 text-center">
                      Conduct interviews with business owners to gather insights
                      on market trends, customer preferences, and competitor
                      analysis.
                    </p>
                  </div>
                  {/* Use Case 2 */}
                  <div className="bg-white rounded-lg p-6 shadow-md flex flex-col justify-between items-center transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl">
                    <FaUsers className="text-5xl text-green-600 mb-4" />
                    <h3 className="text-xl font-semibold text-gray-900 mb-2">
                      Product Development
                    </h3>
                    <p className="text-base text-gray-600 text-center">
                      Engage with business owners to validate product ideas,
                      gather feedback on features, and prioritize development
                      efforts.
                    </p>
                  </div>
                  {/* Use Case 3 */}
                  <div className="bg-white rounded-lg p-6 shadow-md flex flex-col justify-between items-center transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl">
                    <FaHandsHelping className="text-5xl text-green-600 mb-4" />
                    <h3 className="text-xl font-semibold text-gray-900 mb-2">
                      Partnership Opportunities
                    </h3>
                    <p className="text-base text-gray-600 text-center">
                      Collaborate with business owners to explore partnership
                      opportunities, joint ventures, or strategic alliances.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="text-center p-10">
              No profile found.
              <button
                onClick={() => setEditMode(true)}
                className="mt-4 inline-flex items-center justify-center px-5 py-2 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
              >
                Create One?
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BusinessProfile;
