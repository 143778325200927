import React from "react";
import Header from "../components/Header";

const styles = {
  fontFamily: "Inter, sans-serif",
};

const Privacy = () => {
  return (
    <div className="font-inter antialiased bg-white text-gray-900 tracking-tight">
      <Header />
      <div className="grow p-4">
        <section className="bg-gradient-to-b from-gray-50 to-green-100 min-h-screen">
          <div className="max-w-4xl mx-auto py-12">
            <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
            <p className="mb-2">
              <strong>Last updated on March 10, 2023.</strong>
            </p>
            <p className="mb-4">
              This website is operated by feedbackbyai. Throughout the site, the
              terms "we", "us", and "our" refer to the trading name,
              feedbackbyai.com.
            </p>
            <p className="mb-4">
              feedbackbyai.com offers this website, including all information,
              tools and services available from this website to you, the user.
              This "Privacy Policy" has been adopted to explain what information
              might be collected on our website, how we use this information and
              under what circumstances we may disclose that information to
              third-parties.
            </p>
            <h2 className="text-xl font-semibold mb-3">Personal Information</h2>
            <p className="mb-4">
              We only ask for personal information when we truly need it to
              provide you with service. We collect that personal information by
              fair and lawful means and with your knowledge and consent.
            </p>
            <h2 className="text-xl font-semibold mb-3">Data Retention</h2>
            <p className="mb-4">
              We only retain collected information for as long as it's necessary
              to provide you with your requested service. What data we store,
              we'll protect and retain within commercially acceptable means to
              prevent loss, theft, unauthorized access, disclosure, copying,
              use, or modification.
            </p>
            <h2 className="text-xl font-semibold mb-3">Third-parties</h2>
            <p className="mb-4">
              We don't share any personally identifying information publicly or
              with third-parties, unless we're required to by law.
            </p>
            <h2 className="text-xl font-semibold mb-3">Links to Other Sites</h2>
            <p className="mb-4">
              Our website may contain links to external sites that are operated
              by third-parties. Please be aware that we have no control over the
              content and terms of these sites, and we cannot accept
              responsibility or liability for their respective privacy policies.
            </p>
            <h2 className="text-xl font-semibold mb-3">Cookies</h2>
            <p className="mb-4">
              feedbackbyai.com uses "cookies" on this site. A cookie is a piece
              of data that is stored on the hard drive of a website visitor to
              assist us in improving your access to our website and identifying
              repeat visitors to our site. Cookies allow us to track and target
              our users' interests in order to improve their experience on our
              site and for advertising purposes. The use of a cookie on our
              website is not linked to any personally identifiable information.
              By continuing to use or navigate our website, you hereby
              acknowledge and agree to feedbackbyai.com's usage of cookies.
            </p>
            <h2 className="text-xl font-semibold mb-3">
              Changes to This Policy
            </h2>
            <p className="mb-4">
              We reserve the right to modify our Privacy Policy at any time.
              Changes and updates will take effect immediately upon going live
              on our website. feedbackbyai.com advises its visitors to
              frequently visit this page to check for any changes to its Privacy
              Policy. Your continued use of this site after any change will
              confirm your acceptance of those changes.
            </p>

            <h2 className="text-xl font-semibold mb-3">Contact</h2>
            <p className="mb-4">
              If you would like to ask any questions or provide feedback
              regarding this Privacy Policy, please reach out to us at
              business@feedbackbyai.com
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Privacy;
