import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ReportNavbar from "./ReportNavbar";
import TableOfContentDemo from "./MinireportTableOfContent";
import api from "./axiosConfig";
import SectionDemo from "./MiniSectionDemo";
// import tableOfContentData from "./TableOfContent.json";
import tableOfContentData from "./MinireportTableOfContent.json";
import SerpAiLogo from "../images/serpai.png";
import TopAiToolsLogo from "../images/topaitools.png";
import ThereIsAnAIForThatLogo from "../images/thereisanaiforthat.png";
import ToolifyLogo from "../images/toolify.png";
import AiHuntLogo from "../images/aihunt.png";
import ReportDemoNavbar from "./ReportDemoNavbar";
import TopBanner from "./TopBanner";

const UnpurchasedBanner = () => {
  return (
    <div className="bg-indigo-50 border-t-4 border-indigo-500 rounded-lg text-indigo-900 px-6 py-4 shadow-lg my-4 mx-4">
      <div className="flex flex-col md:flex-row items-center">
        <div className="shrink-0">
          <svg
            className="fill-current h-8 w-8 text-indigo-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M8.707 14.707a1 1 0 0 1-1.414 0L2 9.414 3.414 8l4.293 4.293L14.586 5H16v1.586l-7.293 7.293z" />
          </svg>
        </div>
        <div className="mt-4 md:mt-0 md:ml-4">
          <h3 className="font-semibold text-lg md:text-4xl mb-5">
            Mini Report : An app to find the best wifi hotspots when you travel
          </h3>
          {/* <p className="mt-1 text-sm md:text-base">
            AI-powered 45+ feature analysis to validate, refine, and optimize
            your business idea.
          </p> */}
          <hr />
          <p className="mt-2 text-md md:text-lg font-semibold italic">
            ⭐⭐⭐⭐⭐ Trusted by over 5,000 businesses, investors, and analysts
          </p>
          <div className="flex flex-wrap justify-center md:justify-start items-center gap-4 mt-4">
            {/* Image sources are placeholders; replace with your actual paths */}
            <img src={TopAiToolsLogo} alt="TopAiTools Logo" className="h-10" />
            <span className="font-semibold text-gray-800">TopAiTools</span>
            <img src={SerpAiLogo} alt="Serp.ai Logo" className="h-10" />
            <span className="font-semibold text-gray-800">SERPAI</span>
            <img
              src={ThereIsAnAIForThatLogo}
              alt="ThereIsAnAIForThat Logo"
              className="h-10"
            />
            <span className="font-semibold text-gray-800">
              ThereIsAnAIForThat
            </span>

            <img src={ToolifyLogo} alt="ToolifyLogo" className="h-10" />
            <span className="font-semibold text-gray-800">Toolify.AI</span>
            {/* <img src={AiHuntLogo} alt="AiHuntLogo" className="h-10" />
            <span className="font-semibold text-gray-800">AHunt</span> */}
          </div>
        </div>
      </div>
    </div>
  );
};
const MiniReportDemo = () => {
  const [currentSection, setCurrentSection] = useState(1);
  const [purchased, setPurchased] = useState(true);
  const [sectionsToLoad, setSectionsToLoad] = useState([]);
  const { reportId } = useParams();
  const [userEmail, setUserEmail] = useState("");
  const [credits, setCredits] = useState(0);

  useEffect(() => {
    // Handle scroll to determine the current section
    const handleScroll = () => {
      const sections = document.querySelectorAll("section");
      sections.forEach((section, index) => {
        const rect = section.getBoundingClientRect();
        if (rect.top <= 0 && rect.bottom >= 0) {
          setCurrentSection(index + 1);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const shouldLoadSection = (sectionNumber) => {
    // const sectionNum = parseInt(sectionNumber, 10); // Parse section number to integer
    // return purchased || sectionNum <= 3; // Allow first three sections without purchase
    return true;
  };

  return (
    <div className="font-inter antialiased bg-white text-gray-900 tracking-tight">
      {/* <TopBanner /> */}
      <ReportDemoNavbar purchased={purchased} />
      <div className="h-1 bg-gray-200 fixed top-0 left-0 z-50 w-full">
        <div
          style={{
            width: `${
              (currentSection / tableOfContentData.sections.length) * 100
            }%`,
          }}
          className="h-1 bg-gradient-to-r from-blue-600 to-blue-400"
        ></div>
      </div>
      <div className="flex flex-col md:flex-row grow">
        <div className="md:w-1/4 w-full p-4 md:p-0">
          <TableOfContentDemo
            currentSection={currentSection}
            purchased={purchased}
          />
        </div>
        <div className="md:w-3/4 w-full p-4 md:pt-0">
          {/* {!purchased && <UnpurchasedBanner />} */}
          {<UnpurchasedBanner />}

          {tableOfContentData.sections.map((section, sectionIndex) =>
            section.content.map((content, contentIndex) => (
              <React.Fragment key={content.number}>
                {shouldLoadSection(content.number) && (
                  <SectionDemo
                    sectionTitle={content.text}
                    sectionNumber={content.number} // Passing section number separately
                    // reportId={4}
                    // reportId={2}
                    id={content.number}
                    purchased={purchased}
                    userEmail={userEmail}
                    defaultOpen={content.number <= 6} // Open the first five sections
                    // defaultOpen={sectionIndex === 0 && contentIndex === 0} // Open only the first section
                  />
                )}
              </React.Fragment>
            ))
          )}
          {/* {!purchased && <BlurComponent />} */}
        </div>
      </div>
    </div>
  );
};

export default MiniReportDemo;
