import React from "react";
import tableOfContentData from "./MinireportTableOfContent.json"; // Adjust the path as necessary

function MinireportTableOfContent({ currentSection }) {
  return (
    <section className="relative pt-20 bg-gray-100 p-4 rounded-lg shadow-md h-screen sticky top-0 overflow-y-auto">
      <h2 className="font-extrabold text-2xl mb-4 border-b pb-2">
        Table of Contents
      </h2>
      <ol className="list-decimal pl-5 mt-4">
        {tableOfContentData.sections.map((section, idx) => (
          <li
            key={idx}
            className={`mt-2 ${
              currentSection === idx + 1 ? "text-blue-600" : "text-gray-800"
            }`}
          >
            {section.name}
            <ol className="list-decimal pl-5 mt-2">
              {section.content.map((content, cIdx) => (
                <li key={cIdx}>
                  <a
                    href={`#${content.number}`}
                    className="text-sm text-gray-700 underline"
                  >
                    {`${content.text}`}
                  </a>
                </li>
              ))}
            </ol>
          </li>
        ))}
      </ol>
    </section>
  );
}

export default MinireportTableOfContent;
