// import { useState, useRef, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import { Transition } from "@headlessui/react";
// import LogoutButton from "./LogoutButton";
// import jwtDecode from "jwt-decode";

// export default function ReportMobileMenu({ purchased, saveReportAsPDF, isGeneratingPDF }) {
//   const navigate = useNavigate();
//   const [mobileNavOpen, setMobileNavOpen] = useState(false);
//   const [isLoggedIn, setIsLoggedIn] = useState(false);

//   const trigger = useRef(null);
//   const mobileNav = useRef(null);

//   useEffect(() => {
//     const clickHandler = ({ target }) => {
//       if (!mobileNav.current || !trigger.current) return;
//       if (!mobileNavOpen || mobileNav.current.contains(target) || trigger.current.contains(target)) return;
//       setMobileNavOpen(false);
//     };
//     document.addEventListener("click", clickHandler);
//     return () => document.removeEventListener("click", clickHandler);
//   });

//   useEffect(() => {
//     const keyHandler = ({ keyCode }) => {
//       if (!mobileNavOpen || keyCode !== 27) return;
//       setMobileNavOpen(false);
//     };
//     document.addEventListener("keydown", keyHandler);
//     return () => document.removeEventListener("keydown", keyHandler);
//   });

//   const handleNavigation = (path) => {
//     navigate(path);
//     setMobileNavOpen(false);
//   };

//   // Function to check the validity of the token
//   const isTokenValid = (token) => {
//     try {
//       const decodedToken = jwtDecode(token);
//       const currentDate = new Date();
//       return decodedToken.exp * 1000 > currentDate.getTime();
//     } catch (error) {
//       return false;
//     }
//   };

//   // Check if user is logged in based on the token
//   useEffect(() => {
//     const token = localStorage.getItem("access");
//     if (token && isTokenValid(token)) {
//       setIsLoggedIn(true);
//     } else {
//       setIsLoggedIn(false);
//     }
//   }, []);

//   const handleLogout = () => {
//     localStorage.removeItem("access");
//     setIsLoggedIn(false);
//     navigate("/signin/");
//     setMobileNavOpen(false);
//   };

//   // Modify this function to not close the menu and stop event propagation
//   const handleSaveAsPDF = (e) => {
//     e.preventDefault(); // Prevent default link action
//     e.stopPropagation(); // Stop the event from propagating up to the document level
//     saveReportAsPDF(); // Call the function to save the report as PDF
//   };

//   return (
//     <div className="flex md:hidden">
//       {/* Hamburger button */}
//       <button
//         ref={trigger}
//         className={`hamburger ${mobileNavOpen ? "active" : ""}`}
//         aria-controls="mobile-nav"
//         aria-expanded={mobileNavOpen}
//         onClick={() => setMobileNavOpen(!mobileNavOpen)} // Toggle mobileNavOpen state
//       >
//         <span className="sr-only">Menu</span>
//         <svg className="w-6 h-6 fill-current text-gray-900" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
//           <rect y="4" width="24" height="2" />
//           <rect y="11" width="24" height="2" />
//           <rect y="18" width="24" height="2" />
//         </svg>
//       </button>

//       {/* Mobile navigation */}
//       <div ref={mobileNav}>
//         <Transition
//           show={mobileNavOpen}
//           enter="transition ease-out duration-200 transform"
//           enterFrom="opacity-0 scale-95"
//           enterTo="opacity-100 scale-100"
//           leave="transition ease-in duration-75 transform"
//           leaveFrom="opacity-100 scale-100"
//           leaveTo="opacity-0 scale-95"
//           //   className="absolute top-16 inset-x-0 z-50 bg-blue shadow-lg"
//           className="absolute top-16 inset-x-0 bg-white shadow-lg"
//           style={{ zIndex: 1000 }} // Apply a high z-index here
//         >
//           <ul className="px-5 py-2">
//             <li>
//               <a
//                 href="/feedback/"
//                 className="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out"
//               >
//                 AI Character Feedback
//               </a>
//             </li>

//             <li>
//               <a
//                 href="/credits/"
//                 className="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out"
//               >
//                 Credits & Recharge
//               </a>
//             </li>

//             <li>
//               <a
//                 href="mailto:business@feedbackbyai.com" // This will open the user's default mail client with your email address
//                 className="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out"
//               >
//                 Contact Us
//               </a>
//             </li>

//             {purchased && (
//               <li>
//                 {isGeneratingPDF ? (
//                   <span className="text-center py-2 font-bold">PDF preparation is underway, please wait...</span>
//                 ) : (
//                   <a
//                     href="#"
//                     onClick={handleSaveAsPDF}
//                     className="text-gray-600 font-bold hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out"
//                   >
//                     Save as PDF
//                   </a>
//                 )}
//               </li>
//             )}

//             {/* Conditional Rendering for Login/Logout */}
//             {isLoggedIn ? (
//               <li>
//                 <button
//                   onClick={handleLogout}
//                   className="flex font-medium w-full text-gray-600 hover:text-gray-900 bg-green-600 py-2 justify-center"
//                 >
//                   Logout
//                 </button>
//               </li>
//             ) : (
//               <li>
//                 <button
//                   onClick={() => navigate("/signin/")}
//                   className="flex font-medium w-full text-gray-600 hover:text-gray-900 bg-green-600 py-2 justify-center"
//                 >
//                   Login
//                 </button>
//               </li>
//             )}
//           </ul>
//         </Transition>
//       </div>
//     </div>
//   );
// }

import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Transition } from "@headlessui/react";
import LogoutButton from "./LogoutButton";
import jwtDecode from "jwt-decode";

export default function ReportMobileMenu({ purchased, saveReportAsPDF, saveReportAsDocs, isGeneratingPDF, isGeneratingDocs }) {
  const navigate = useNavigate();
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const trigger = useRef(null);
  const mobileNav = useRef(null);

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNav.current || !trigger.current) return;
      if (!mobileNavOpen || mobileNav.current.contains(target) || trigger.current.contains(target)) return;
      setMobileNavOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  const handleNavigation = (path) => {
    navigate(path);
    setMobileNavOpen(false);
  };

  // Function to check the validity of the token
  const isTokenValid = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      const currentDate = new Date();
      return decodedToken.exp * 1000 > currentDate.getTime();
    } catch (error) {
      return false;
    }
  };

  // Check if user is logged in based on the token
  useEffect(() => {
    const token = localStorage.getItem("access");
    if (token && isTokenValid(token)) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("access");
    setIsLoggedIn(false);
    navigate("/signin/");
    setMobileNavOpen(false);
  };

  const handleSaveAsPDF = (e) => {
    e.preventDefault(); // Prevent default link action
    e.stopPropagation(); // Stop the event from propagating up to the document level
    saveReportAsPDF(); // Call the function to save the report as PDF
  };

  const handleSaveAsDocs = (e) => {
    e.preventDefault(); // Prevent default link action
    e.stopPropagation(); // Stop the event from propagating up to the document level
    saveReportAsDocs(); // Call the function to save the report as Docs
  };

  return (
    <div className="flex md:hidden">
      {/* Hamburger button */}
      <button
        ref={trigger}
        className={`hamburger ${mobileNavOpen ? "active" : ""}`}
        aria-controls="mobile-nav"
        aria-expanded={mobileNavOpen}
        onClick={() => setMobileNavOpen(!mobileNavOpen)} // Toggle mobileNavOpen state
      >
        <span className="sr-only">Menu</span>
        <svg className="w-6 h-6 fill-current text-gray-900" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <rect y="4" width="24" height="2" />
          <rect y="11" width="24" height="2" />
          <rect y="18" width="24" height="2" />
        </svg>
      </button>

      {/* Mobile navigation */}
      <div ref={mobileNav}>
        <Transition
          show={mobileNavOpen}
          enter="transition ease-out duration-200 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
          className="absolute top-16 inset-x-0 bg-white shadow-lg"
          style={{ zIndex: 1000 }} // Apply a high z-index here
        >
          <ul className="px-5 py-2">
            <li>
              <a
                href="/feedback/"
                className="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out"
              >
                AI Character Feedback
              </a>
            </li>

            <li>
              <a
                href="/dashboard/"
                className="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out"
              >
                100+ Page Report
              </a>
            </li>

            <li>
              <a
                href="/credits/"
                className="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out"
              >
                Credits & Recharge
              </a>
            </li>

            <li>
              <a
                href="mailto:business@feedbackbyai.com"
                className="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out"
              >
                Contact Us
              </a>
            </li>

            {purchased && (
              <>
                <li>
                  {isGeneratingPDF ? (
                    <span className="text-center py-2 font-bold">PDF preparation is underway, please wait...</span>
                  ) : (
                    <a
                      href="#"
                      onClick={handleSaveAsPDF}
                      className="text-gray-600 font-bold hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out"
                    >
                      Save as PDF
                    </a>
                  )}
                </li>
                <li>
                  {isGeneratingDocs ? (
                    <span className="text-center py-2 font-bold">Docs preparation is underway, please wait...</span>
                  ) : (
                    <a
                      href="#"
                      onClick={handleSaveAsDocs}
                      className="text-gray-600 font-bold hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out"
                    >
                      Save as Docs
                    </a>
                  )}
                </li>
              </>
            )}

            {/* Conditional Rendering for Login/Logout */}
            {isLoggedIn ? (
              <li>
                <button
                  onClick={handleLogout}
                  className="flex font-medium w-full text-gray-600 hover:text-gray-900 bg-green-600 py-2 justify-center"
                >
                  Logout
                </button>
              </li>
            ) : (
              <li>
                <button
                  onClick={() => navigate("/signin/")}
                  className="flex font-medium w-full text-gray-600 hover:text-gray-900 bg-green-600 py-2 justify-center"
                >
                  Login
                </button>
              </li>
            )}
          </ul>
        </Transition>
      </div>
    </div>
  );
}
