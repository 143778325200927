import React, { useState, useEffect } from "react";

const ScaledUpIdeas = ({ ideas, onViewReportClick, isLoading, purchased }) => {
  const [currentMessage, setCurrentMessage] = useState(0);
  const [error, setError] = useState(false);

  const loadingMessages = [
    "Generating 3 New Ideas for you...",
    "Compiling data into something awesome...",
    "Crunching numbers and analyzing trends...",
    "Just a moment, we're tailoring your experience...",
    "Polishing up the details for a perfect presentation...",
    "Almost there, weaving everything together...",
    "Final checks in progress for top-notch accuracy...",
  ];

  useEffect(() => {
    if (isLoading) {
      const intervalId = setInterval(() => {
        setCurrentMessage((prevMessage) =>
          prevMessage === loadingMessages.length - 1 ? 0 : prevMessage + 1
        );
      }, 6000); // Change message every 3 seconds

      return () => clearInterval(intervalId);
    }
  }, [isLoading, loadingMessages.length]);

  if (isLoading) {
    return (
      <div className="text-center py-10">
        <span className="text-xl font-semibold">
          {loadingMessages[currentMessage]}
        </span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-10">
        <span className="text-xl font-semibold text-red-600">
          Network response was not ok. Error fetching report data.
        </span>
        <button
          className="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600 mt-4"
          onClick={() => window.location.reload()} // Or call a specific function to retry
        >
          Retry
        </button>
      </div>
    );
  }

  if (!ideas || !Array.isArray(ideas)) {
    return (
      <div className="text-center py-10">
        <span className="text-xl font-semibold text-red-500">
          No ideas available
        </span>
      </div>
    );
  }

  // Function to truncate the description to 10 words
  const truncateDescription = (description) => {
    const words = description.split(" ");
    return words.slice(0, 15).join(" ") + (words.length > 15 ? "..." : "");
  };

  return (
    <div className="relative w-full max-w-4xl mx-auto mt-10 shadow-lg">
      <div className="flex flex-col items-center justify-center">
        <h1 className="text-4xl font-bold mb-8 ">
          🔒 3 Improved Versions Of Your Idea
        </h1>
        {ideas.map((idea, index) => (
          <div
            key={idea.id}
            className={`w-full max-w-3xl bg-white shadow-xl rounded-lg p-6 mb-6 ${
              !purchased && index > 0 ? "opacity-80" : ""
            }`}
          >
            {index === 0 || purchased ? (
              <>
                <h3 className="text-2xl font-bold mb-2 leading-normal font-inter antialiased text-blue-600">
                  {purchased || index === 0 ? `🌟${idea.title}🌟` : "🔒"}
                </h3>
                <p className="text-lg text-gray-700 mb-4 leading-normal font-inter antialiased">
                  {purchased || index === 0
                    ? index === 0 && !purchased
                      ? truncateDescription(idea.description)
                      : idea.description
                    : "Idea locked. Purchase the report to view."}
                </p>
              </>
            ) : (
              <p className="text-xl text-gray-900 mb-4 leading-normal font-inter antialiased">
                🔒 Idea locked. Purchase the report to view.
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScaledUpIdeas;
