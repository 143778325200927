import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
export const metadata = {
  title: "Reset Password - Simple",
  description: "Page description",
};

function ResetPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [feedbackMsg, setFeedbackMsg] = useState("");
  const [isError, setIsError] = useState(false);
  const { uidb64, token } = useParams();
  const navigate = useNavigate(); // Use this instead of useHistory

  const handleReset = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setIsError(true);
      setFeedbackMsg("Passwords do not match!");
      return;
    }

    const res = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}reset-password/${uidb64}/${token}/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ password }),
      }
    );

    const data = await res.json();
    if (res.status === 200) {
      setIsError(false);
      setFeedbackMsg(data.message);
      setTimeout(() => {
        navigate("/signin/"); // Use navigate instead of history.push
      }, 2000);
    } else {
      setIsError(true);
      setFeedbackMsg(data.error || "Something went wrong.");
    }
  };

  return (
    <section className="bg-gradient-to-b from-gray-100 to-white">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        {/* Adding navigation bar at the top */}
        <nav className=" py-4 mb-8 ">
          <div className="container mx-auto">
            <div className="flex justify-between items-center">
              {/* Navigation text to go to homepage */}
              <Link to="/" className="text-2xl font-bold text-gray-800">
                FeedbackbyAI
              </Link>
              {/* (Add other nav items here if needed in the future) */}
            </div>
          </div>
        </nav>

        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h1 className="h1 mb-4">Reset Your Password</h1>
            <p className="text-xl text-gray-600">
              Please enter your new password twice to confirm.
            </p>
          </div>

          <div className="max-w-sm mx-auto">
            <form onSubmit={handleReset}>
              <div className="flex flex-wrap -mx-3 mb-4">
                {feedbackMsg && (
                  <p
                    className={`text-sm mt-2 ${
                      isError ? "text-red-500" : "text-green-500"
                    }`}
                  >
                    {feedbackMsg}
                  </p>
                )}
                <div className="w-full px-3 mb-4">
                  <label
                    className="block text-gray-800 text-sm font-medium mb-1"
                    htmlFor="password"
                  >
                    New Password <span className="text-red-600">*</span>
                  </label>
                  <input
                    id="password"
                    type="password"
                    className="form-input w-full text-gray-800"
                    placeholder="Enter your new password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                <div className="w-full px-3">
                  <label
                    className="block text-gray-800 text-sm font-medium mb-1"
                    htmlFor="confirm-password"
                  >
                    Confirm New Password <span className="text-red-600">*</span>
                  </label>
                  <input
                    id="confirm-password"
                    type="password"
                    className="form-input w-full text-gray-800"
                    placeholder="Re-enter your new password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mt-6">
                <div className="w-full px-3">
                  <button className="btn text-white bg-blue-600 hover:bg-blue-700 w-full">
                    Reset Password
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ResetPassword;
