import React from "react";
import Header from "../components/Header";
import Hero from "../components/NewHero";
import Problem from "./Problem";
import Footer from "./Footer";
import HowItWorks from "./HowItWorks";
import PricingTables from "./Pricing";
import Faqs from "./Faqs";
import Cta from "./Cta";
import { useState, useEffect } from "react";
import TopBanner from "./TopBanner";
import IframeResizer from "iframe-resizer-react";
import { motion } from "framer-motion";
import { ArrowRight, Zap, Sparkles, ChevronRight } from "lucide-react";

const CountdownTimer = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents = Object.keys(timeLeft).map((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    return (
      <span key={interval}>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  return <div>{timerComponents.length ? timerComponents : <span>Time's up!</span>}</div>;
};

// const Banner = () => {
//   // const endTime = new Date();
//   // endTime.setHours(endTime.getHours() + 6); // Set 24 hours from now

//   return (
//     <div className="bg-green-700 text-white text-center py-2 px-4">
//       <p className="text-sm md:text-base font-normal inline-block mr-2">
//         {/* 🌟 New Feature Alert: Now you can Generate Report in 30+ Languages
//         ,Export Your Report as Docs & PDF ! 🚀 */}
//         {/* 🌟 Booths are Coming Soon! Connect directly with potential customers,
//         partners, investors, or collaborators. */}
//         {/* 🌟 Booths Coming Soon: Attract new potential customers, partners,
//         investors, or find new collaborations for your business through virtual
//         booths! 🚀 */}
//         {/* 🌟 Get Ready: Virtual Booths Incoming! Engage with new customers,
//         partners, investors, and collaborations through your virtual booth! 🚀 */}
//         {/* 🌟 Get Ready: Virtual Booths Incoming! Engage with new customers,
//         partners, investors, and collaborations through virtual booths! 🚀 */}
//         {/* 🌟 Get Ready: Virtual Booths Incoming! Showcase your business to 5000
//         businesses on FeedbackbyAI ! */}
//         {/* 🌟 Get Ready: Virtual Booths Incoming! Let 5000 business discover your
//         businesses through virtual booths ! */}
//         {/* 🌟 Get Ready: Virtual Booths Incoming! Engage with new customers,
//         partners, investors, and collaborations through your virtual booth! 🚀 */}
//         {/* 🌟 Get Ready: Virtual Booths Incoming! Engage with new customers,
//         partners, investors, and collaborations globally through your virtual
//         booth! 🌍🚀 */}
//         {/* 🌟 Get Ready: Virtual Booths Incoming! Prepare to connect with new
//         customers, partners, investors, and collaborations worldwide through
//         your virtual booth! 🌍🚀 */}
//         {/* 🌟 Get Ready: Virtual Booths Incoming! Engage globally with new
//         customers, partners, investors, and collaborations through your virtual
//         booth! 🌍🚀 */}
//         {/* 🌟 Unlock Business Growth with Warm Introductions. Coming Soon! Say
//         goodbye to cold outreach and wasted time.🌍🚀  */}
//         {/* 🌟 Warm Introduction Engine Coming Soon : Get ready to up your business
//         network with 5000 businesses on FeedbackbyAI. <br />
//         Say goodbye to cold outreach and wasted time.🌍🚀 */}
//         {/* 🌟 Warm Introduction Engine: Early Access Opens Soon! Tap into our
//         exclusive network of 5,000+ businesses before everyone else.
//         <br />
//         Say goodbye to cold outreach. 🌍🚀 */}
//         {/* 🌟 Warm Introduction Engine: Early Access Opens Soon! Tap into our
//         exclusive network of 5,000+ businesses before everyone else. 🔒🚀
//         <br />
//         Say goodbye to cold outreach. */}
//         {/* 🌟 Warm Introduction Engine: Early Access Opens Soon! Unlock our
//         exclusive network of 5,000+ businesses before anyone else. 🔒🚀
//         <br />
//         Ditch cold outreach. Warm intros close deals faster. */}
//         {/* 🌟 Land your dream clients and partnerships – unlock exclusive warm
//         introductions with 5,000+ businesses. Coming Soon 🚀 */}
//         {/* Connect with 5,000+ global businesses for dream clients and
//         partnerships. Unlock exclusive warm introductions. Coming Soon 🚀 */}
//         {/* 🌟 Warm Introduction Engine: Early Access Opens Soon! Unlock our
//         exclusive network of 5,000+ businesses before anyone else. 🔒🚀
//         <br />
//         Ditch cold outreach. Warm intros close deals faster. */}
//         {/* 🌟 Coming Soon : The Pitch Room ! 🚀 */}
//         {/* This is how you'll pitch in 2024 🚀 The Pitch Room. Coming soon! */}
//         {/* 🌟 Tired of endless networking events? The Pitch Room is coming to
//         streamline connections. 🚀 */}
//         {/* 🌟 The Pitch Room is coming soon. 🚀 */}
//         {/* 🌟 Coming Soon : The Pitch Room 🚀 */}
//         {/* 🌟 NeedFinderAI - A new way to find ideas from social listening coming this Sunday 🚀 */}
//         🌟 NeedFinderAI: Is Coming Soon! 🚀
//         {/* We are currently experiencing high demand. Some features may be affected
//         .We are working on the fix. */}
//         {/* Engage with new customers,
//         partners, investors, and collaborations through virtual booths!  */}
//       </p>

//       {/* <span className="text-sm md:text-base font-semibold inline-block">
//         <CountdownTimer targetDate={endTime} />
//       </span> */}
//     </div>
//   );
// };

// You can switch between 'blue' and 'green' themes
// const theme = "blue"; // or 'green'
const theme = "green"; // or 'green'

const Banner = () => {
  const bgColor = theme === "blue" ? "bg-blue-600" : "bg-green-700";
  const textColor = "text-white";
  const linkColor = "text-white hover:text-white";

  return (
    <motion.div className={`${bgColor} py-3 px-4`} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
      <div className="container mx-auto flex items-center justify-center">
        <motion.p
          className={`text-sm md:text-base font-normal ${textColor} tracking-wide`}
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          Introducing NeedFinderAI. Real-Time AI Market Research.
          <motion.a
            href="https://needfinderai.com"
            target="_blank"
            rel="noopener noreferrer"
            className={`ml-2 ${linkColor} font-bold hover:underline  underline transition-all duration-300`}
            whileHover={{ x: 3 }}
          >
            Try it now &gt;
          </motion.a>
        </motion.p>
      </div>
    </motion.div>
  );
};

const styles = {
  fontFamily: "Inter, sans-serif",
};

const LandingPage = () => {
  return (
    <div style={styles} className="font-inter antialiased bg-white text-gray-900 tracking-tight ">
      <Banner />
      <Header />
      {/* Add the Banner component */}
      <div className="grow ">
        <Hero />

        <HowItWorks />
        <Problem />
        <PricingTables />

        <Faqs />
        <Cta />
        <Footer />
      </div>
    </div>
  );
};

export default LandingPage;
