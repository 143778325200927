// import React, { useState, useEffect } from "react";
// import { useParams, useNavigate } from "react-router-dom";
// import ReactMarkdown from "react-markdown";
// import api from "./axiosConfig";

// function TableOfContentDemo({ currentSection, purchased }) {
//   const { reportId } = useParams();
//   const [reportData, setReportData] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const navigate = useNavigate();

//   useEffect(() => {
//     api
//       .post(`table-of-content/`, { report_id: 4 })
//       .then((response) => {
//         setReportData(response.data);
//         setLoading(false);
//       })
//       .catch((err) => {
//         setError(err.message);
//         setLoading(false);
//       });
//   }, [reportId]);

//   if (loading) {
//     return <p>Loading...</p>;
//   }

//   if (error) {
//     return <p>Error: {error}</p>;
//   }

//   if (reportData) {
//     const { table_of_contents, input } = reportData;

//     return (
//       <section className="relative  bg-gray-100 p-4 rounded-lg shadow-md h-screen sticky top-0 overflow-y-auto">
//         <h2 className="font-extrabold text-2xl mb-4 border-b pb-2">
//           Table of Contents
//         </h2>
//         <ol className="list-decimal pl-5 mt-4">
//           {table_of_contents.sections.map((section, idx) => (
//             <li
//               key={idx}
//               className={`mt-2 ${
//                 currentSection === idx + 1 ? "text-blue-600" : "text-gray-800"
//               }`}
//             >
//               {section.number}. {section.name}
//               <ol className="list-decimal pl-5 mt-2">
//                 {section.content.map((content, cIdx) => (
//                   <li key={cIdx}>
//                     <a
//                       href={`#${content.number}`}
//                       className="text-sm text-gray-700 underline"
//                     >
//                       {`${content.number}. ${content.text}`}
//                     </a>
//                   </li>
//                 ))}
//               </ol>
//             </li>
//           ))}
//         </ol>
//       </section>
//     );
//   } else {
//     return <p>No data available.</p>;
//   }
// }

// export default TableOfContentDemo;

import React from "react";
import tableOfContentData from "./TableofContentDemo.json"; // Adjust the path as necessary

function TableOfContentDemo({ currentSection }) {
  return (
    <section className="relative pt-5 bg-gray-100 p-4 rounded-lg shadow-md h-screen sticky top-0 overflow-y-auto">
      <h2 className="font-extrabold text-2xl mb-4 border-b pb-2">
        Table of Contents
      </h2>
      <ol className="list-decimal pl-5 mt-4">
        {tableOfContentData.sections.map((section, idx) => (
          <li
            key={idx}
            className={`mt-2 ${
              currentSection === idx + 1 ? "text-blue-600" : "text-gray-800"
            }`}
          >
            {section.number}. {section.name}
            <ol className="list-decimal pl-5 mt-2">
              {section.content.map((content, cIdx) => (
                <li key={cIdx}>
                  <a
                    href={`#${content.number}`}
                    className="text-sm text-gray-700 underline"
                  >
                    {`${content.number}. ${content.text}`}
                  </a>
                </li>
              ))}
            </ol>
          </li>
        ))}
      </ol>
    </section>
  );
}

export default TableOfContentDemo;
