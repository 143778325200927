// import React from "react";
// import FeatureImage from "../images/f1.png"; // Make sure the path is correct
// import FeatureImage2 from "../images/f3.png"; // Make sure the path is correct
// import Satisfaction from "../images/satisfaction.png";
// import FeatureImage3 from "../images/features-top-image.png"; // Make sure the path is correct

// export default function Problem() {
//   const redirectToSignUp = () => {
//     // Implement the logic for redirection to signup page
//     // console.log("Redirecting to sign up page...");
//     window.location.href = "/signup";
//   };

//   return (
//     <section className="py-12 md:py-20">
//       <div className="max-w-6xl mx-auto px-4 sm:px-6">
//         {/* Section header */}

//         <div className="max-w-6xl mx-auto px-4 sm:px-6 pt-20">
//           {/* Section header */}
//           <div className="text-center pb-12 md:pb-16">
//             <h2 className="text-4xl lg:text-5xl font-bold text-gray-800 mb-4">
//               {/* 📊 In-Depth Reporting Tool */}
//               {/* 📊 100+ Page In-Depth Report: Your Business Roadmap */}
//               📊 100+ Page In-Depth Report : Your Launch Roadmap: Data-Driven,
//               Action-Focused
//             </h2>
//             <p className="text-lg md:text-xl text-gray-600 mb-6">
//               Stop wasting time on guesswork. Get the analysis, the ideal
//               customer profile, and the step-by-step plan you need.
//               {/* From idea analysis, mvp building, ui decisions, pre launch hype, ad
//             copies, getting your first customers, building pitch deck and
//             finally raising capital . In just one click save months of guesswork
//             and research . Let AI do all the research and business planning for
//             you.{" "} */}
//               {/* <a className="bg-green-300 text-2xl" href="/previewx/">
//               Read sample business report here <span className="ml-2">👉</span>
//             </a> */}
//               {/* the hard work
//             research and business planning for you . Eliminate guesswork and
//             make launch with confidence. */}
//               {/* Your 60 page business roadmap does all the
//             planning for you . */}
//             </p>
//             {/* <button
//             onClick={redirectToSignUp}
//             className="animate-pulse mt-4 px-8 py-3 text-lg font-bold text-white bg-green-500 rounded-lg shadow-lg hover:bg-green-600 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
//           >
//             Sign Up for Free Preview
//           </button> */}
//           </div>

//           {/* Feature and Image Container */}
//           <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
//             {/* Features List */}
//             <div className="space-y-4">
//               <div className="text-xl text-gray-800 font-semibold mb-3">
//                 {/* 🎯 55-Section InDepth Report */}
//                 {/* 🎯 Comprehensive Analysis */}
//                 {/* 🎯 Investment-Ready in No Time */}⌛ Save Time
//               </div>
//               <p className="text-gray-600">
//                 {/* 55-Section Indepth-Report Covering nearly every topic for your
//               launch. */}
//                 Weeks of research and guesswork done in minutes.
//               </p>

//               <div className="text-xl text-gray-800 font-semibold mb-3">
//                 {/* 💡 Idea Evaluation */}
//                 💡 Strategic Insights
//               </div>
//               <p className="text-gray-600">
//                 {/* Explore originality, market fit, customer personas, hooks,blue
//               ocean markets and more. */}
//                 Make informed decisions. Pick and choose best ideas.
//                 {/* Understand market fit, customer needs, and more. */}
//               </p>

//               {/* <div className="text-xl text-gray-800 font-semibold mb-3">
//               📈 Marketing for MVP
//             </div>
//             <p className="text-gray-600">
//               Chart out customer journeys, create buzz, and harness
//               psychological tactics.
//             </p> */}

//               <div className="text-xl text-gray-800 font-semibold mb-3">
//                 {/* 💰 Raise Capital */}
//                 💰 Increased Revenues
//               </div>
//               <p className="text-gray-600">
//                 {/* Prepare irresistible pitches and strategically address investor
//               relations. */}
//                 {/* Craft pitches that resonate with data-backed confidence. */}
//                 Launch with clarity and enjoy higher earnings.
//               </p>
//             </div>

//             {/* Image */}
//             <div className="flex justify-center md:justify-end">
//               <div className="border-4 border-white shadow-xl rounded-lg overflow-hidden">
//                 <img
//                   src={FeatureImage2}
//                   alt="Feature illustration"
//                   className="w-full h-auto"
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//         <hr className="mt-10" />
//         <div className="text-center pb-12 md:pb-16 mt-10">
//           <h2 className="text-4xl lg:text-5xl font-bold text-gray-800 mb-4">
//             {/* 🤖 AI Feedback Tool */}
//             {/* Feedback Tool: Uncover Concerns About Your Business Idea */}
//             {/* 50+ AI Characters: Run Instant User Interviews */}
//             50+ AI Characters : Get the Honest Feedback Friends Won't Give
//           </h2>
//           <p className="text-lg md:text-xl text-gray-600 mb-6 leading-snug">
//             {/* Useful For Brainstorming and Instant Feedback */}
//             {/* Leave the sugar-coated advice behind. Our 50+ AI Experts from remote
//             workers, investors, software developers, online trolls and more help
//             you uncover genuine concerns about your business idea before you hit
//             market.{" "} */}
//             AI interviews uncover hidden concerns about your idea, so you can
//             address them BEFORE launch.
//           </p>
//           {/* <button
//             onClick={redirectToSignUp}
//             className=" animate-pulse mt-4 px-8 py-3 text-lg font-bold text-white bg-green-500 rounded-lg shadow-lg hover:bg-green-600 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
//           >
//             Sign Up for Free Preview
//           </button> */}
//         </div>

//         {/* Feature and Image Container */}
//         <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
//           {/* Features List */}
//           <div className="space-y-4">
//             <div className="text-xl text-gray-800 font-semibold mb-3">
//               {/* ⚡ Instant Clarity */}⚡ Instant Clarity
//             </div>
//             <p className="text-gray-600">
//               {/* Receive unbiased, real-time feedback from our AI to refine your
//               business concepts. */}
//               Instantly uncover genuine concerns about your business idea.
//               {/* Sharpen your idea with instant, real-time feedback. */}
//             </p>

//             <div className="text-xl text-gray-800 font-semibold mb-3">
//               {/* 🌐 Diverse Feedbacks */}⌛ Save Time
//             </div>
//             <p className="text-gray-600">
//               {/* Gain insights from a global panel of 30+ AI Characters, offering
//               varied perspectives on your idea. */}
//               {/* Insights from a variety of 51+ AI personalities, enriching your
//               strategy. */}
//               {/* Pivot or Pursue Ideas More Confidently */}
//               Avoid time consuming user interviews.
//             </p>

//             <div className="text-xl text-gray-800 font-semibold mb-3">
//               {/* 🎯 Build Region-Specific Products */}
//               🎯 Sharper Business Ideas
//             </div>
//             <p className="text-gray-600">
//               {/* Customize your products with targeted feedback from specific
//               markets and demographics. */}
//               Launch Sharply In Your Target Market.
//             </p>

//             {/* <div className="text-xl text-gray-800 font-semibold mb-3">
//               ⏰ Save Time and Money
//             </div>
//             <p className="text-gray-600">
//               Streamline your research process, reducing the need for extensive
//               user interviews and market studies.
//             </p> */}
//           </div>

//           {/* Image */}
//           <div className="flex justify-center md:justify-end">
//             <div className="border-4 border-white shadow-xl rounded-lg overflow-hidden">
//               <img
//                 src={FeatureImage}
//                 alt="Feature illustration"
//                 className="w-full h-auto"
//               />
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* <div className="max-w-6xl mx-auto px-4 sm:px-6 pt-20">
//         <div className="text-center pb-12 md:pb-16">
//           <h2 className="text-4xl lg:text-5xl font-bold text-gray-800 mb-4">
//             👨🏻‍💻 IT Development: Trusted Senior Developers For Your Business Idea
//           </h2>
//           <p className="text-lg md:text-xl text-gray-600 mb-6">
//             Need trusted development team for your startup idea? Connect with
//             senior developers at FeedbackbyAI and kickstart your project faster.
//             3-days risk free trial.
//           </p>
//         </div>

//         <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
//           <div className="space-y-4">
//             <div className="text-xl text-gray-800 font-semibold mb-3">
//               ⌛ Trusted Senior Developers
//             </div>
//             <p className="text-gray-600">
//               Only senior developers work on your project
//             </p>

//             <div className="text-xl text-gray-800 font-semibold mb-3">
//               💡 Weekly Status Updates
//             </div>
//             <p className="text-gray-600">2 status update calls per week</p>

//             <div className="text-xl text-gray-800 font-semibold mb-3">
//               💰 No Hidden Cost
//             </div>
//             <p className="text-gray-600">
//               Pay Based on the developers your project requires
//             </p>
//           </div>

//           <div className="flex justify-center md:justify-end">
//             <div className="border-4 border-white shadow-xl rounded-lg overflow-hidden">
//               <img
//                 src={FeatureImage}
//                 alt="Feature illustration"
//                 className="w-full h-auto"
//               />
//             </div>
//           </div>
//         </div>
//       </div> */}
//       <hr />

//       {/* 100% Satisifaction Guarantee */}
//       {/* <section className="bg-white py-20">
//         <div className="max-w-4xl mx-auto px-4 sm:px-6 text-center">
//           <div className="flex flex-col items-center justify-center space-y-6">
//             <img
//               className="h-32 w-32 md:h-48 md:w-48" // Increase the size of the image
//               src={Satisfaction} // Make sure to use the correct path to your image
//               alt="100% Satisfaction Guarantee Badge"
//             />

//             <p className="mt-8 text-gray-600 text-xl italic">
//               If you're not satisfied with our service, we'll refund you without
//               any questions asked! But we're sure you'll{" "}
//               <span className="text-green-500">love our advice </span> for
//               success in entrepreneurship!
//             </p>
//             <p className="mt-4 text-gray-600 text-md italic">
//               *Refund valid within 24 hours of purchase, provided less than 5
//               sections of the report are generated.
//             </p>
//           </div>
//         </div>
//       </section> */}
//     </section>
//   );
// }

import React from "react";
import { HiClock, HiLightningBolt, HiCurrencyDollar, HiUserGroup, HiCube, HiAdjustments } from "react-icons/hi";
import FeatureImage from "../images/f3.png";
import FeatureImage2 from "../images/f1.png";

const FeatureCard = ({ icon, title, description }) => (
  <div className="flex items-start space-x-4">
    <div className="flex-shrink-0">
      <div className="bg-green-100 rounded-full p-3">{icon}</div>
    </div>
    <div>
      <h3 className="text-lg font-semibold text-gray-800 mb-1">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  </div>
);

export default function Problem() {
  return (
    <section className="py-20 bg-gradient-to-b from-white to-green-50">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="mb-20">
          <h2 className="text-4xl lg:text-5xl font-bold text-gray-900 mb-6 text-center">
            100+ Page In-Depth Report: Your Data-Driven Launch Roadmap
          </h2>
          <p className="text-xl text-gray-700 mb-12 text-center max-w-3xl mx-auto">
            Stop wasting time on guesswork. Get the analysis, the ideal customer profile, and the step-by-step plan you need.
          </p>

          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div className="space-y-8">
              <FeatureCard
                icon={<HiClock className="h-6 w-6 text-green-600" />}
                title="Save Time"
                description="Weeks of research and guesswork done in minutes."
              />
              <FeatureCard
                icon={<HiLightningBolt className="h-6 w-6 text-green-600" />}
                title="Strategic Insights"
                description="Make informed decisions. Pick and choose the best ideas."
              />
              <FeatureCard
                icon={<HiCurrencyDollar className="h-6 w-6 text-green-600" />}
                title="Increased Revenues"
                description="Launch with clarity and enjoy higher earnings."
              />
            </div>
            <div className="flex justify-center md:justify-end">
              <img src={FeatureImage} alt="Feature illustration" className="rounded-lg shadow-xl max-w-full h-auto" />
            </div>
          </div>
        </div>

        <div className="mt-24">
          <h2 className="text-4xl lg:text-5xl font-bold text-gray-900 mb-6 text-center">
            50+ AI Characters: Get Honest Feedback Friends Won't Give
          </h2>
          <p className="text-xl text-gray-700 mb-12 text-center max-w-3xl mx-auto">
            AI interviews uncover hidden concerns about your idea, so you can address them BEFORE launch.
          </p>

          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div className="order-2 md:order-1">
              <img src={FeatureImage2} alt="AI Characters illustration" className="rounded-lg shadow-xl max-w-full h-auto" />
            </div>
            <div className="space-y-8 order-1 md:order-2">
              <FeatureCard
                icon={<HiCube className="h-6 w-6 text-green-600" />}
                title="Instant Clarity"
                description="Instantly uncover genuine concerns about your business idea."
              />
              <FeatureCard
                icon={<HiUserGroup className="h-6 w-6 text-green-600" />}
                title="Save Time"
                description="Avoid time-consuming user interviews."
              />
              <FeatureCard
                icon={<HiAdjustments className="h-6 w-6 text-green-600" />}
                title="Sharper Business Ideas"
                description="Launch sharply in your target market."
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
