// export default function HowItWorks() {
//   return (
//     <section>
//       <div className="max-w-6xl mx-auto px-4 sm:px-6">
//         <div className="py-12 md:py-16 border-t border-gray-200">
//           <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
//             <p className="text-green-800 font-bold text-lg md:text-xl">
//               {/* Less intrusive More relevant */}
//             </p>
//             <h1 className="text-5xl md:text-5xl lg:text-6xl font-extrabold leading-tight mb-4">
//               {/* How it Works <br /> */}
//               {/* but read pinned comment */}
//               {/* A New Era of Business Planning
//               Awaits */}
//               {/* Save months of guesswork and frustration */}
//               {/* Identify Risks, Reduce Business Failure */}
//               Buried in endless research, but still unsure about your market?
//             </h1>
//           </div>
//           {/* Items */}
//           <div className="relative max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-none">
//             <div
//               className="absolute top-1/2 w-full h-1 bg-gradient-to-r from-green-800 to-teal-300 hidden lg:block"
//               aria-hidden="true"
//             ></div>

//             {/* 1st item */}
//             <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
//               <div className="flex justify-center items-center h-12 w-12 rounded-full bg-green-800 text-white font-bold mb-3">
//                 1
//               </div>
//               <h4 className="text-2xl font-bold leading-snug tracking-tight mb-1">
//                 Enter Your Idea
//               </h4>
//               <p className="text-gray-600 text-center">
//                 {/* Simply type in your brainchild and let our AI take the reins. */}
//                 {/* Enter your business idea, target customers and questions you
//                 want to ask. */}
//                 {/* We need just need one input, your business idea */}
//                 AI pinpoints your ideal customer, no more wasted ad dollars.
//               </p>
//             </div>

//             {/* 2nd item */}
//             <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
//               <div className="flex justify-center items-center h-12 w-12 rounded-full bg-green-800 text-white font-bold mb-3">
//                 2
//               </div>
//               <h4 className="text-2xl font-bold leading-snug tracking-tight mb-1">
//                 {/* Embrace Your Insights */}
//                 100+ Page Business Report
//               </h4>
//               <p className="text-gray-600 text-center">
//                 {/* Preview the first 3 sections for free and ask up to 3 questions
//                 with our feedback tool. */}
//                 {/* Dive into AI-powered analysis with free previews and questions. */}
//                 {/* Next With 1-Click generate your in-depth business report */}
//                 {/* In just 1-Click AI prepares entire 100+ Page business report for
//                 you. */}
//                 100+ page business plan cuts through the overwhelm.
//               </p>
//             </div>

//             {/* 2nd item */}
//             <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
//               <div className="flex justify-center items-center h-12 w-12 rounded-full bg-green-800 text-white font-bold mb-3">
//                 3
//               </div>
//               <h4 className="text-2xl font-bold leading-snug tracking-tight mb-1">
//                 {/* Choose Your Path */}
//                 {/* Get Instant Feedback */}
//                 Run AI User Interviews
//               </h4>
//               <p className="text-gray-600 text-center">
//                 {/* Opt for instant Feedback or a detailed In-Depth Report. */}
//                 {/* Choose instant feedback or a comprehensive report. */}
//                 {/* Brainstorm with AI Characters as if you are talking to your
//                 users. */}
//                 {/* Run User Interviews with 50+ AI Characters. */}
//                 AI interviews uncover honest feedback friends won't give.
//                 {/* Brainstorm with AI Characters as if you are talking to your
//                 users. */}
//                 {/* Instantly Uncover Concerns About
//                 Your Business Idea */}
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }

import React from "react";
import { HiLightningBolt, HiDocumentText, HiUserGroup } from "react-icons/hi";

const StepCard = ({ number, title, description, icon }) => (
  <div className="bg-white rounded-xl shadow-lg p-6 relative overflow-hidden">
    <div className="absolute top-0 left-0 bg-green-600 text-white text-2xl font-bold w-12 h-12 flex items-center justify-center rounded-br-xl">
      {number}
    </div>
    <div className="mt-8">
      {icon}
      <h3 className="text-xl font-bold mt-4 mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  </div>
);

export default function HowItWorks() {
  return (
    <section className="bg-gradient-to-b from-white to-green-50 py-20">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="text-center mb-16">
          <h2 className="text-4xl md:text-5xl font-bold text-gray-900 mb-4">
            Buried in endless research, but still unsure about your market?
          </h2>
          <p className="text-xl text-gray-700 max-w-3xl mx-auto">
            Our streamlined process turns your idea into a comprehensive business plan in three simple steps.
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          <StepCard
            number="1"
            title="Enter Your Idea"
            description="AI pinpoints your ideal customer, eliminating wasted ad dollars and guesswork."
            icon={<HiLightningBolt className="text-green-500 text-4xl" />}
          />
          <StepCard
            number="2"
            title="100+ Page Business Report"
            description="Our AI crafts a comprehensive business plan, cutting through the overwhelm of traditional research."
            icon={<HiDocumentText className="text-green-500 text-4xl" />}
          />
          <StepCard
            number="3"
            title="Run AI User Interviews"
            description="AI-powered interviews uncover honest feedback that friends and family might not provide."
            icon={<HiUserGroup className="text-green-500 text-4xl" />}
          />
        </div>
      </div>
    </section>
  );
}
