import React, { useState, useEffect } from "react";
import ReactCountryFlag from "react-country-flag";
import { getName } from "country-list";
import axios from "axios";

// Example PPP data - extend this based on your requirements
const pppGroups = {
  "0.1-0.2": {
    countries: ["Iran", "Sudan"],
    // discount: 70,
    discount: 30,
    code: "PDTOMQOR30",
    // code: "PDNTSAAG70", // Replace with actual coupon code
  },
  "0.2-0.3": {
    countries: [
      "Afghanistan",
      "Egypt",
      "Kyrgyzstan",
      "Sri Lanka",
      "Burundi",
      "Bhutan",
      "Georgia",
      "Laos",
      "Lebanon",
      "Libya",
      "Myanmar",
      "Pakistan",
      "Sierra Leone",
      "Tajikistan",
      "Nepal",
      "Suriname",
      "Uzbekistan",
    ],
    // discount: 60,
    discount: 30,
    code: "PDTOMQOR30",
    // code: "PDLJXOLV60", // Replace with actual coupon code
  },
  "0.3-0.4": {
    countries: [
      "Bangladesh",
      "Burkina Faso",
      "Benin",
      "Colombia",
      "Ethiopia",
      "Cambodia",
      "Armenia",
      "Angola",
      "Azerbaijan",
      "Bolivia",
      "Belarus",
      "Algeria",
      "Eritrea",
      "Ghana",
      "Gambia",
      "Guinea-Bissau",
      "Guyana",
      "Indonesia",
      "Kenya",
      "Kazakhstan",
      "Moldova",
      "North Macedonia",
      "Mali",
      "Malawi",
      "Malaysia",
      "Mozambique",
      "Nigeria",
      "Nicaragua",
      "Philippines",
      "Paraguay",
      "Russia",
      "Rwanda",
      "Thailand",
      "Tanzania",
      "Ukraine",
      "Uganda",
      "Vietnam",
      "India",
      "Madagascar",
      "Mongolia",
      "Mauritania",
      "Mauritius",
      "Somalia",
      "East Timor",
      "Tunisia",
      "Turkey",
      "Zambia",
    ],
    // discount: 50,
    discount: 30,
    code: "PDTOMQOR30",
    // code: "PDJWCEVV50", // Replace with actual coupon code
  },
  "0.4-0.5": {
    countries: [
      "Albania",
      "Bosnia and Herzegovina",
      "Bulgaria",
      "Botswana",
      "Cameroon",
      "Cape Verde",
      "Equatorial Guinea",
      "Honduras",
      "Jordan",
      "Argentina",
      "Bahrain",
      "Brazil",
      "Democratic Republic of the Congo",
      "Ivory Coast",
      "Dominican Republic",
      "Fiji",
      "Guinea",
      "Iraq",
      "Jamaica",
      "Comoros",
      "Liberia",
      "Lesotho",
      "Montenegro",
      "Oman",
      "Panama",
      "Mexico",
      "Namibia",
      "Saudi Arabia",
      "Peru",
      "Poland",
      "Romania",
      "Serbia",
      "Seychelles",
      "Senegal",
      "El Salvador",
      "Turkmenistan",
      "Taiwan",
      "Brunei",
      "Morocco",
      "Maldives",
      "Niger",
      "Eswatini",
      "Chad",
      "Togo",
      "Yemen",
      "South Africa",
    ],
    discount: 30,
    code: "PDTOMQOR30",
    // discount: 40,
    // code: "PDPEMKVY40", // Replace with actual coupon code
  },
  "0.5-0.6": {
    countries: [
      "United Arab Emirates",
      "Djibouti",
      "Ecuador",
      "Guatemala",
      "Croatia",
      "Lithuania",
      "Central African Republic",
      "Chile",
      "Costa Rica",
      "Czech Republic",
      "Gabon",
      "Grenada",
      "Haiti",
      "Hungary",
      "Kuwait",
      "Latvia",
      "Sao Tome and Principe",
      "Trinidad and Tobago",
      "Saint Vincent and the Grenadines",
    ],
    // discount: 30,
    discount: 20,
    code: "PDCMDMGO20",
    // code: "PDTOMQOR30",
    // code: "PDTOMQOR30", // Replace with actual coupon code
  },
  "0.6-0.7": {
    countries: [
      "Estonia",
      "Greece",
      "Saint Kitts and Nevis",
      "Belize",
      "Republic of the Congo",
      "China",
      "Kiribati",
      "Saint Lucia",
      "Portugal",
      "Malta",
      "Qatar",
      "Slovenia",
      "Slovakia",
      "Papua New Guinea",
      "Singapore",
      "Dominica",
      "Macau",
      "Tonga",
      "Uruguay",
      "Venezuela",
      "Samoa",
    ],
    discount: 10,
    code: "PDCMDMGO10", // Replace with actual coupon code
    // discount: 20,
    // code: "PDCMDMGO20", // Replace with actual coupon code
  },
  "0.7-0.8": {
    countries: [
      "Spain",
      "Hong Kong",
      "Aruba",
      "Cyprus",
      "Italy",
      "South Korea",
      "Antigua and Barbuda",
      "San Marino",
      "Zimbabwe",
      "Japan",
      "United States",
      "Germany",
      "United Kingdom",
      "France",
      "Canada",
      "Australia",
      "Netherlands",
      "Sweden",
      "Switzerland",
      "Belgium",
      "Norway",
    ],
    discount: 10,
    code: "PDCMDMGO10", // Replace with actual coupon code
  },
  // "0.8-0.9": {
  //   countries: [
  //     "Belgium",
  //     "Bahamas",
  //     "Germany",
  //     "France",
  //     "Palau",
  //     "Solomon Islands",
  //     "Nauru",
  //   ],
  //   discount: 10,
  //   code: "COUPON10", // Replace with actual coupon code
  // },
  // Add more groups as needed
};

// Define the Feature component for list items
const Feature = ({ children }) => {
  // Function to selectively style certain phrases
  const styleSpecificPhrases = (text) => {
    if (text.includes("Full")) {
      return <span className="font-bold text-black">{text}</span>;
    } else if (text.includes("Feedbacks")) {
      return <span className="font-bold text-black">{text}</span>;
    } else {
      return text;
    }
  };

  return (
    <li className="flex items-center mb-2">
      <svg className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
      </svg>
      <span className="text-gray-700">{styleSpecificPhrases(children)}</span>
    </li>
  );
};

const PricingCard = ({ plan, price, features, bestValue }) => {
  const handleScrollToSection = () => {
    const pricingSection = document.getElementById("pricing");
    if (pricingSection) {
      pricingSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div
      className={`max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden hover:shadow-2xl transition-shadow duration-300 ${
        bestValue ? "border border-green-500" : ""
      }`}
    >
      {bestValue && (
        <div className="text-center py-2 bg-green-100">
          <span className="text-green-600 font-semibold">Best Value Plan!</span>
        </div>
      )}
      <div className="px-6 py-8">
        <div className="text-center">
          <span className="text-3xl font-bold text-gray-900">{plan}</span>
          <div className="mt-2">
            <span className="text-2xl font-semibold text-green-600">${price}</span>
          </div>
        </div>
        <hr className="my-6 border-gray-200" />
        <ul className="mb-4 space-y-3">
          {features.map((benefit, idx) => {
            // Check if the benefit contains a number and highlight it
            const isHighlight = /\d+/.test(benefit);
            return (
              <Feature key={idx} highlight={isHighlight}>
                {benefit}
              </Feature>
            );
          })}
        </ul>
      </div>
      <div className="px-6 pt-6 pb-8 bg-gray-50 rounded-b-lg">
        <button
          className="btn-sm block w-full px-4 py-3 text-sm font-semibold text-center text-white bg-green-600 hover:bg-green-700"
          onClick={handleScrollToSection}
        >
          Get Started
        </button>
      </div>
    </div>
  );
};

const PricingTables = () => {
  const [userCountry, setUserCountry] = useState("");
  const [discountInfo, setDiscountInfo] = useState({});

  useEffect(() => {
    fetchUserLocation();
  }, []);

  const fetchUserLocation = async () => {
    try {
      // Replace 'YOUR_TOKEN' with your ipinfo.io API token
      const response = await axios.get("https://ipinfo.io?token=54225501c526ff");
      const countryCode = response.data.country;
      const countryName = getName(countryCode); // Convert country code to country name
      setUserCountry(countryName);
      findDiscountInfo(countryName);
    } catch (error) {
      console.error("Error fetching user location:", error);
    }
  };

  const findDiscountInfo = (countryName) => {
    Object.entries(pppGroups).forEach(([_, groupInfo]) => {
      if (groupInfo.countries.includes(countryName)) {
        setDiscountInfo(groupInfo);
      }
    });
  };

  // Define the features for each pricing card

  const free = [
    "3 Sections of Full Report",
    // "Free Preview of Mini Report",
    "1 Feedback",
    "Expires 48 hours from Signup",
    "No access to PDF downloads",
    "Limited to 1 AI Expert(Default)",
  ];

  // const mini = [
  //   "Free Preview of Full Report",
  //   "10 Page Mini Report",
  //   "1 Feedback",
  //   " Save/Download Your Report as PDF",
  //   "Limited to 1 AI Expert(Default)",
  // ];

  const featuresBasic = [
    "1 Full 100+ Page Business Report",
    // "Free Preview of Mini Report",
    "25 AI Character Feedbacks",
    " Save/Download Your Report as Docs & PDF",
    "Access to all 50+ AI Experts",
  ];
  const featuresSelect = [
    "2 Full 100+ Page Business Report",
    // "Free Preview of Mini Report",
    "40 AI Character Feedbacks",
    " Save/Download Your Report as Docs & PDF",
    "Access to all 50+ AI Experts",
  ];

  const featuresStandard = [
    "3 Full 100+ Page Business Reports",
    // "Free Preview of Mini Report",
    "50 AI Character Feedbacks",
    " Save/Download Your Report as Docs & PDF",
    "Access to all 50+ AI Experts",
  ];

  const featuresPremium = [
    "Save $350",
    "10 Full 100+ Page Business Reports",
    // "Free Preview of Mini Report",
    "100 AI Character Feedbacks",
    " Save/Download Your Report as Docs & PDF",
    "Access to all 50+ AI Experts",
  ];

  const featuresUltimate = [
    "Save $984",
    "25 Full 100+ Page Business Reports",
    // "Free Preview of Mini Report",
    "250 AI Character Feedbacks",
    " Save/Download Your Report as Docs & PDF",
    "Access to all 50+ AI Experts",
  ];

  return (
    <section className="bg-gradient-to-b from-gray-50 to-gray-100 py-12" id="pricings">
      <div className="container mx-auto px-4 max-w-5xl">
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold mb-4">Pricing For Every Business*</h1>
          <div id="creditx">
            {userCountry && discountInfo.code && (
              <div className="bg-green-700 text-white py-8 px-6 rounded-md text-center flex items-center justify-center space-x-4">
                <p className="text-xl md:text-2xl font-normal">
                  Hello{" "}
                  <ReactCountryFlag
                    countryCode={userCountry.substring(0, 2)}
                    svg
                    className="inline-block text-3xl md:text-4xl" // Adjust size as needed for the flag
                  />
                  Hey! It looks like you are from <strong>{userCountry}</strong>. We support Parity Purchasing Power, so if you need it, use
                  code <strong>"{discountInfo.code}"</strong> to get <strong>{discountInfo.discount}%</strong> off our plan at checkout.
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-8 credits-pricing">
          {/* <PricingCard plan="Free" price="0" features={free} /> */}
          {/* <PricingCard plan="Mini" price="9.99" features={mini} /> */}
          <PricingCard plan="Starter" price="59" features={featuresBasic} />
          <PricingCard plan="Select" price="99" features={featuresSelect} />
          {/* <PricingCard plan="Growth" price="104" features={featuresStandard} /> */}
          <PricingCard plan="Pro" price="248" features={featuresPremium} bestValue />
          {/* <PricingCard
            plan="Ultimate"
            price="491"
            features={featuresUltimate}
            bestValue
          /> */}
        </div>
      </div>
    </section>
  );
};

export default PricingTables;
