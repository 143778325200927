// import React from "react";
// import SerpAiLogo from "../images/serpai.png";
// import TopAiToolsLogo from "../images/topaitools.png";
// import ThereIsAnAIForThatLogo from "../images/thereisanaiforthat.png";
// import ToolifyLogo from "../images/toolify.png";
// import AiHuntLogo from "../images/aihunt.png";
// import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
// import { auth } from "../firebaseConfig"; // Adjust the path to your firebaseConfig file
// import { FcGoogle } from "react-icons/fc"; // Importing the Google icon
// import api from "./axiosConfig"; // Adjust the path to your axiosConfig
// import IframeResizer from "iframe-resizer-react";

// export default function NewHero() {
//   const redirectToSignUp = () => {
//     window.location.href = "/signup"; // make sure this route is correct for your app
//     // window.location.href = "/previewx"; // make sure this route is correct for your app
//   };

//   const handleGoogleSignUp = async () => {
//     const provider = new GoogleAuthProvider();
//     try {
//       const result = await signInWithPopup(auth, provider);
//       const token = result.user.accessToken;
//       const response = await api.post("/google-login/", { token });
//       if (response.status === 200) {
//         localStorage.setItem("access", response.data.access);
//         window.location.href = "/dashboard/";
//       }
//     } catch (error) {
//       console.error("Error with Google Sign Up:", error.message);
//     }
//   };

//   return (
//     <>
//       {/* Pricing Section with Gradient Background */}
//       <section
//         className="bg-gradient-to-b from-gray-50 to-green-100 flex items-center justify-center"
//         id="pricing"
//       >
//         <div className="max-w-6xl mx-auto px-4 sm:px-6 text-center">
//           <div className="pt-10 pb-6 sm:pt-20 sm:pb-12 md:pt-15 md:pb-20">
//             <h1
//               className="text-5xl sm:text-5xl md:text-5xl lg:text-6xl font-extrabold leading-tighter tracking-tighter mb-4"
//               style={{ color: "#1A202C" }}
//             >
//               {/* Validate Your Business Idea In Seconds */}
//               {/* Validate Your Next Big Idea In Seconds */}
//               Stop Second-Guessing Your Idea. Get Launch-Ready with AI.
//             </h1>
//             <p className="md:text-2xl lg:text-2xl text-xl text-gray-700 mb-8">
//               {/* Save weeks of guesswork and research. Make informed decisions
//               using 50+ AI experts and a full 60 page in-depth business report.
//               Bring your business to life with AI-backed validation. */}
//               {/* Save months of guesswork and research. Let AI in minutes validate
//               your business idea with 50+ AI Characters and craft a 100+ Page
//               Business Report based on your idea potential. */}
//               {/* Save months of planning and research. Run instant user interviews
//               with 50+ AI Characters. Generate a 100+ Page Business Report based
//               on your idea potential. */}
//               {/* Do you have a business idea? Now save months of planning and
//               research work using FeedbackbyAI. Run instant user interviews with
//               50+ AI Characters and generate a 100+ Page Business Report based
//               on your idea potential. */}
//               {/* Now save months of business planning and research. */}
//               {/* Identify
//               potential risks and avoid business failures.  */}
//               {/* Skip months of uncertainty and exhaustive research. Let AI run
//               instant user interviews with 50+ AI Characters and craft a 100+
//               Page Business Report based on your business idea potential. */}
//               {/* Stop wasting time on guesswork and endless research. Let AI run
//               instant user interviews with 50+ AI Characters and craft a 100+
//               Page Business Report based on your business idea potential. */}
//               Validate your business, pinpoint your ideal customer, and get the
//               roadmap you need to succeed – all with the power of AI.
//               {/* Get AI-powered market research, brutally honest user interviews,
//               and a data-driven business plan to launch with confidence. */}
//               {/* Bring your business to
//               life with AI validation. */}
//               {/* Try our free previews to decide for yourself. */}
//               <br />
//               <a
//                 href="/previewx/"
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 className="text-blue-500 hover:text-blue-600 underline mt-4 inline-block"
//               >
//                 📘 Sample Full Business Report Available!
//               </a>
//             </p>
//             <div className="space-y-4 md:space-y-0 md:flex md:justify-center md:gap-4 pt-5">
//               <button
//                 type="button"
//                 onClick={redirectToSignUp}
//                 className="animate-pulse w-full md:w-auto px-5 py-3 text-lg font-bold text-white bg-green-500 rounded-md shadow hover:bg-green-600 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
//               >
//                 Start Free with Email
//                 {/* Signup with Email */}
//                 {/* Read Sample Full Report */}
//                 {/* See Full Business Reports */}
//               </button>
//               <button
//                 onClick={handleGoogleSignUp}
//                 className="animate-pulse flex items-center justify-center text-lg bg-white border border-gray-300 hover:bg-gray-100 w-full md:w-auto py-3 px-5 rounded"
//                 style={{ boxShadow: "0 2px 4px 0 rgba(0,0,0,0.20)" }}
//               >
//                 <FcGoogle className="text-2xl mr-3" />
//                 Start Free with Google
//                 {/* Signup with Google */}
//               </button>
//             </div>
//             {/* User Thumbnail Images */}
//             <div className="flex justify-center items-center flex-wrap gap-0 mb-4 mt-5">
//               <p className="text-md font-normal italic text-gray-900 ml-2">
//                 {/* 🌟 Try Free Previews: Of Both Our Full Business Reports &
//                 Feedback Tool With Your Business Idea 🚀 */}
//                 {/* 🌟 Try Free Previews With Your Business Idea: Full Business
//                 Reports ,AI Interviews 🚀 */}
//                 🌟 Try Free Previews With Your Business Idea: Full Business
//                 Reports ,AI Interviews 🚀
//               </p>
//               {/* Add more images as needed */}
//             </div>
//           </div>

//           <div className="flex justify-center mt-0">
//             <div className="w-full max-w-lg px-4">
//               <div className="aspect-w-16 aspect-h-9">
//                 <iframe
//                   className="w-full h-full"
//                   src="https://www.youtube.com/embed/7VSRdf-JzCU?si=-qk1qmIFvNP5vQbM"
//                   title="FeedbackbyAI"
//                   frameBorder="0"
//                   allowFullScreen
//                 ></iframe>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       {/* <PricingTables /> */}
//       <div className="text-center">
//         <h3 className="text-center text-4xl lg:text-5xl text-gray-800 mb-4 font-bold mt-5">
//           Wall of Love
//         </h3>
//         <IframeResizer
//           src="https://embed-v2.testimonial.to/w/feedbackbyai"
//           style={{ width: "1px", minWidth: "100%" }}
//         />
//       </div>

//       {/* Featured On Section */}
//       {/* <section className="bg-white py-20">
//         <div className="max-w-6xl mx-auto px-4 sm:px-6 text-center">
//           <h2 className="text-5xl font-bold mb-10">Featured On</h2>
//           <div className="flex flex-wrap justify-center items-center gap-8">
//             <div className="flex items-center gap-2">
//               <img
//                 src={TopAiToolsLogo}
//                 alt="TopAiTools Logo"
//                 className="h-12"
//               />
//               <span className="font-semibold text-gray-800">TopAiTools</span>
//             </div>
//             <div className="flex items-center gap-2">
//               <img src={SerpAiLogo} alt="Serp.ai Logo" className="h-12" />
//               <span className="font-semibold text-gray-800">SERPAI</span>
//             </div>
//             <div className="flex items-center gap-2">
//               <img
//                 src={ThereIsAnAIForThatLogo}
//                 alt="ThereIsAnAIForThat Logo"
//                 className="h-12"
//               />
//               <span className="font-semibold text-gray-800">
//                 ThereIsAnAIForThat
//               </span>
//             </div>
//             <div className="flex items-center gap-2">
//               <img src={ToolifyLogo} alt="ToolifyLogo" className="h-12" />
//               <span className="font-semibold text-gray-800">Toolify.ai</span>
//             </div>
//             <div className="flex items-center gap-2">
//               <img src={AiHuntLogo} alt="AiHuntLogo" className="h-12" />
//               <span className="font-semibold text-gray-800">AHunt</span>
//             </div>
//           </div>
//           <p className="mt-8 text-gray-600 text-xl">
//             Join the 5000+ businesses, from startups to corporate giants, who
//             trust FeedbackbyAI every month
//           </p>
//         </div>
//       </section> */}
//     </>
//   );
// }

import React from "react";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../firebaseConfig";
import { FcGoogle } from "react-icons/fc";
import { HiLightningBolt, HiUserGroup, HiDocumentText } from "react-icons/hi";
import api from "./axiosConfig";
import IframeResizer from "iframe-resizer-react";

const FeatureItem = ({ icon, text }) => (
  <div className="flex items-center space-x-2">
    {icon}
    <span className="text-sm md:text-base text-gray-700">{text}</span>
  </div>
);

export default function NewHero() {
  const redirectToSignUp = () => {
    window.location.href = "/signup";
  };

  const handleGoogleSignUp = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const token = result.user.accessToken;
      const response = await api.post("/google-login/", { token });
      if (response.status === 200) {
        localStorage.setItem("access", response.data.access);
        window.location.href = "/dashboard/";
      }
    } catch (error) {
      console.error("Error with Google Sign Up:", error.message);
    }
  };

  return (
    <div className="bg-gradient-to-b from-green-50 to-white">
      <section className="max-w-6xl mx-auto px-4 sm:px-6 py-20 md:py-28">
        <div className="text-center mb-16">
          <h1
            className="text-5xl sm:text-5xl md:text-5xl lg:text-6xl font-extrabold leading-tighter tracking-tighter mb-4"
            style={{ color: "#1A202C" }}
          >
            {/* Validate Your Business Idea In Seconds */}
            {/* Validate Your Next Big Idea In Seconds */}
            Validate Your Next Big Idea In Seconds
          </h1>
          <p className="text-xl md:text-2xl text-gray-700 mb-8 max-w-3xl mx-auto">
            {/* Harness AI to validate your concept, identify your ideal customers, and craft a bulletproof business plan in minutes. */}
            Stop wasting time on guesswork and endless research. Let AI run instant user interviews with 50+ AI Characters and craft a 100+
            Page Business Report based on your business idea potential.
          </p>

          <div className="flex justify-center space-x-8 mb-12">
            <FeatureItem icon={<HiLightningBolt className="text-green-500 text-2xl" />} text="Instant AI-Powered Analysis" />
            <FeatureItem icon={<HiDocumentText className="text-green-500 text-2xl" />} text="100+ Page Business Report" />
            <FeatureItem icon={<HiUserGroup className="text-green-500 text-2xl" />} text="50+ AI Expert Interviews" />
          </div>

          <div className="flex flex-col sm:flex-row justify-center gap-4 mb-8">
            <button
              onClick={redirectToSignUp}
              className="px-8 py-3 text-lg font-semibold text-white bg-green-600 rounded-full hover:bg-green-700 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 shadow-lg"
            >
              Start Your Free Trial
            </button>
            <button
              onClick={handleGoogleSignUp}
              className="flex items-center justify-center px-8 py-3 text-lg font-semibold bg-white text-gray-800 border border-gray-300 rounded-full hover:bg-gray-50 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-opacity-50 shadow-lg"
            >
              <FcGoogle className="text-2xl mr-2" />
              Sign Up with Google
            </button>
          </div>

          <a
            href="/previewx/"
            rel="noopener noreferrer"
            className="text-green-600 hover:text-green-700 font-semibold underline inline-block mb-12"
          >
            View Sample Business Report
          </a>
        </div>

        <div className="max-w-4xl mx-auto mb-20">
          <div className="aspect-w-16 aspect-h-9 rounded-2xl overflow-hidden shadow-2xl">
            <iframe
              className="w-full h-full"
              src="https://www.youtube.com/embed/7VSRdf-JzCU?si=-qk1qmIFvNP5vQbM"
              title="FeedbackbyAI Demo"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </div>

        <div className="text-center">
          <h3 className="text-3xl md:text-4xl font-bold text-gray-800 mb-8">Trusted by Innovative Entrepreneurs</h3>
          <p className="text-xl text-gray-600 mb-12">
            Join thousands of visionaries who've turned their ideas into successful businesses with FeedbackbyAI.
          </p>
          <IframeResizer src="https://embed-v2.testimonial.to/w/feedbackbyai" style={{ width: "1px", minWidth: "100%" }} />
        </div>
      </section>
    </div>
  );
}
