import React, { useState, useEffect } from "react";
import DashboardHeader from "./DashboardHeader";
import api from "./axiosConfig";
import axios from "axios";
import Accordion from "../components/Accordion";

const Feature = ({ children }) => {
  // Function to selectively style certain phrases
  const styleSpecificPhrases = (text) => {
    if (text.includes("Full")) {
      return <span className="font-bold text-black">{text}</span>;
    } else if (text.includes("Feedbacks")) {
      return <span className="font-bold text-black">{text}</span>;
    } else {
      return text;
    }
  };

  return (
    <li className="flex items-center mb-2">
      <svg
        className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0"
        viewBox="0 0 12 12"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
      </svg>
      <span className="text-gray-700">{styleSpecificPhrases(children)}</span>
    </li>
  );
};

const PricingCard = ({ plan, price, features, bestValue }) => {
  const handleScrollToSection = () => {
    const pricingSection = document.getElementById("pricing");
    if (pricingSection) {
      pricingSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div
      className={`max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden hover:shadow-2xl transition-shadow duration-300 ${
        bestValue ? "border border-green-500" : ""
      }`}
    >
      {bestValue && (
        <div className="text-center py-2 bg-green-100">
          <span className="text-green-600 font-semibold">Best Value Plan!</span>
        </div>
      )}
      <div className="px-6 py-8">
        <div className="text-center">
          <span className="text-3xl font-bold text-gray-900">{plan}</span>
          <div className="mt-2">
            <span className="text-2xl font-semibold text-green-600">
              {price}
            </span>
          </div>
        </div>
        <hr className="my-6 border-gray-200" />
        <ul className="mb-4 space-y-3">
          {features.map((benefit, idx) => {
            // Check if the benefit contains a number and highlight it
            const isHighlight = /\d+/.test(benefit);
            return (
              <Feature key={idx} highlight={isHighlight}>
                {benefit}
              </Feature>
            );
          })}
        </ul>
      </div>
      <div className="px-6 pt-6 pb-8 bg-gray-50 rounded-b-lg">
        <button
          className="btn-sm block w-full px-4 py-3 text-sm font-semibold text-center text-white bg-green-600 hover:bg-green-700"
          onClick={handleScrollToSection}
        >
          Get Started
        </button>
      </div>
    </div>
  );
};

export default function ItDevelopment() {
  const featuresBasic = [
    // "3 Predictions about a Past Event",
    "1 Custom Question Asked By You",
    "Best For Yes or No On Your Business Idea",
    "Best For Follow up Questions",
    // "Detailed Yes or No on Your Business Idea",
    // "1 Custom Question Asked By You",
    // "1 Astrological Suggestion for Your Business Idea ",
    // "Free Preview of Mini Report",
    "1 Page Report as PDF",
  ];

  const featuresPremium = [
    // "3 Predictions about a Past Event",
    // "Detailed Yes or No on Your Business Idea",
    // "1 Custom Question Asked By You",
    "Pay Based on the developers your project requires",
    "2 Status Update Calls Per Week Via Video Conference",
    "Developers take care of project development and deployment",
    "Weekly Development Sprint Followed",
    "All Code Resides on Your Github Organization",
    "Project Issues Tracking via Jira,Trello",
    "Only Senior Developers work on your project",
    "Billed Per Month",
    // "Business Partner Compatibility",
    // "Business Name,Logo and Branding",
    // "Region to Start With",
    // "Auspicious day of Launch",

    // "Marketing Alignment",
    // "Astrological Risk Assessment",
    // "Vaastu Compliant Office/Business",

    // // "Free Preview of Mini Report",
    // "10 Page Report as PDF",
  ];

  const [email, setEmail] = useState("");
  const [userReports, setUserReports] = useState([]);
  const [formData, setFormData] = useState({
    businessName: "",
    dateOfBirth: "",
    timeOfBirth: "",
    placeOfBirth: "",
    customQuestion: "",
    planSelected: "Starter",
    chooseAnumber: "",
    businessDetail: "",
  });
  const [businessPartners, setBusinessPartners] = useState([]);
  const [showTooltip1, setShowTooltip1] = useState(false);
  const [showTooltip2, setShowTooltip2] = useState(false);
  const [userPhoto, setUserPhoto] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);

  // Define fetchUserEmail outside of useEffect so it can be reused
  const fetchUserEmail = async () => {
    const token = localStorage.getItem("access");
    if (!token) {
      alert("Please log in first!");
      return;
    }
    const bearer_token = "Bearer " + token;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}get-user-email/`,
        {
          headers: {
            Authorization: bearer_token,
          },
        }
      );
      const data = response.data;
      setEmail(data.email);
    } catch (error) {
      console.error("Error fetching user email:", error);
    }
  };

  // Fetch user email on component mount
  useEffect(() => {
    fetchUserEmail();
  }, []);

  useEffect(() => {
    // Fetch user reports when the component is mounted
    fetchUserReports();
  }, []);

  // Function to clear form data
  const clearFormData = () => {
    setFormData({
      businessName: "",
      // ... reset other form fields
      businessDetail: "",
      customQuestion: "",
    });
  };

  const fetchUserReports = async () => {
    try {
      const response = await api.get("/user-projects/");
      setUserReports(response.data);
    } catch (error) {
      console.error("Error fetching user reports:", error);
    }
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleScrollToSection = () => {
    // Assuming the form section has an id of 'astrology-form-section'
    const section = document.getElementById("astrology-form-section");
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  function capitalizeWords(string) {
    return string
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Prepare data for the API
    const data = {
      businessName: formData.businessName,
      customQuestion: formData.customQuestion,
      businessDetail: formData.businessDetail,
    };

    try {
      const response = await api.post("/development/", data);
      // alert("Your project has been submitted successfully!");
      setShowSuccessMessage(true); // Show success message
      setFadeOut(false); // Reset fadeOut effect
      clearFormData(); // Clear form data

      // Fade out success message after 3 seconds
      setTimeout(() => {
        setFadeOut(true);
        // Hide success message after fade out
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 1000);
      }, 3000);
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle form submission errors
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="font-inter antialiased bg-gradient-to-b from-gray-50 to-green-100 text-gray-900 tracking-tight">
      <DashboardHeader />
      <section className="min-h-screen py-6">
        {/* Stepper Functionality */}

        <div className="container mx-auto px-6 lg:px-8 max-w-5xl rounded-xl">
          <div className="text-center my-6">
            <h1 className="text-6xl font-bold text-gray-900 mb-4">
              {/* Start Discovering Relevant Consultants on FeedbackbyAI Soon! */}
              {/* Start Discovering Consultants on FeedbackbyAI Soon! */}
              Software Development
              {/* Get Your Product Built Faster */}
              {/* Accelerate Your Product Launch with FeedbackbyAI */}
              {/* Building Products */}
            </h1>
            <p className=" text-gray-700 max-w-3xl mx-auto md:text-2xl sm:text-2xl">
              {/* We are excited to bring expert consultants to FeedbackbyAI. If you
              are an industry expert passionate about driving business
              transformation, join us as a consultant and shape the future of
              businesses. */}
              {/* With 2000 new businesses joining FeedbackbyAI every month.We are
              inviting top-tier consultants to help our businesses give 1-1
              advice for every stage of their business journey. */}
              {/* With 27% of people relying on astrology to make business
              decisions. We are trying to leave no stone unturned for your
              business idea validation.We have team up with expert business
              astrologers to help you get astrological perspective on your
              business venture. */}
              {/* Now get to market faster by hiring experienced developers from
              FeedbackbyAI. Get started within 48 hours.Try risk free for 3
              days. */}
              {/* Speed up your go-to-market strategy. Connect with our top-tier
              developers at FeedbackbyAI and kickstart your project faster. Try
              risk free for 3 days. */}
              Need trusted development team for your startup idea? Connect with
              senior developers at FeedbackbyAI and kickstart your project
              faster. 3-day Risk Free Trial.
              {/* We understand finding the right developers at the
              begining of your startup journey can be daunting task. */}
            </p>
            {/* <a
              href="/previewx/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:text-blue-600 underline mt-4 inline-block text-2xl"
            >
              📘 See a Sample Astrology Report
            </a> */}
            {/* Call to Action Button */}
            <button
              onClick={() => {
                document
                  .getElementById("astrology-form-section")
                  .scrollIntoView({ behavior: "smooth" });
              }}
              className="animate-pulse w-full md:w-64 lg:w-96 px-5 py-3 text-lg font-bold text-white bg-green-500 rounded-md shadow hover:bg-green-600 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 mt-10"
            >
              {/* Schedule A Call Now */}
              Get Your Free Project Consultation
            </button>

            <br />

            {/* <a
              // href="/previewx/"
              // target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:text-blue-600 underline mt-4 inline-block text-2xl"
            >
              [ Please note :We are accepting Limited Projects as of Now !]
            </a> */}
          </div>
          <section>
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
              <div className="py-12 md:py-16 border-tx border-gray-200">
                <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                  <p className="text-green-800 font-bold text-lg md:text-xl">
                    {/* Less intrusive More relevant */}
                  </p>
                  <h1 className="text-4xl md:text-5xl lg:text-6xl font-extrabold leading-tight mb-4">
                    {/* How it Works <br /> */}
                    {/* but read pinned comment */}
                    {/* A New Era of Business Planning
              Awaits */}
                    How it Works
                  </h1>
                </div>
                {/* Items */}
                <div className="relative max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-none">
                  <div
                    className="absolute top-1/2 w-full h-1 bg-gradient-to-r from-green-800 to-teal-300 hidden lg:block"
                    aria-hidden="true"
                  ></div>

                  {/* 1st item */}
                  <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
                    <div className="flex justify-center items-center h-12 w-12 rounded-full bg-green-800 text-white font-bold mb-3">
                      1
                    </div>
                    <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">
                      Fill Project Details
                    </h4>
                    <p className="text-gray-600 text-center">
                      {/* Simply type in your brainchild and let our AI take the reins. */}
                      {/* Enter your business idea, target customers and questions you
                want to ask. */}
                      Fill in your project details.
                    </p>
                  </div>

                  {/* 2nd item */}
                  <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
                    <div className="flex justify-center items-center h-12 w-12 rounded-full bg-green-800 text-white font-bold mb-3">
                      2
                    </div>
                    <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">
                      {/* Embrace Your Insights */}
                      Schedule a Call
                    </h4>
                    <p className="text-gray-600 text-center">
                      {/* Preview the first 3 sections for free and ask up to 3 questions
                with our feedback tool. */}
                      {/* Dive into AI-powered analysis with free previews and questions. */}
                      {/* Next With 1-Click generate your in-depth business report */}
                      Within 24 hours we will schedule a call with you
                    </p>
                  </div>

                  {/* 2nd item */}
                  <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
                    <div className="flex justify-center items-center h-12 w-12 rounded-full bg-green-800 text-white font-bold mb-3">
                      3
                    </div>
                    <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">
                      {/* Choose Your Path */}
                      Work Begins
                    </h4>
                    <p className="text-gray-600 text-center">
                      {/* Opt for instant Feedback or a detailed In-Depth Report. */}
                      {/* Choose instant feedback or a comprehensive report. */}
                      {/* Brainstorm with AI Characters as if you are talking to your
                users. */}
                      {/* We email your astrology reports. */}
                      We begin work within 48 hours.
                      {/* Instantly Uncover Concerns About
                Your Business Idea */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            className="bg-gradient-to-b from-gray-50 to-gray-100 py-12"
            id="pricings"
          >
            <div className="container mx-auto px-4">
              <div className="text-center mb-16">
                <h1 className="text-4xl font-bold mb-4">
                  Simple Transparent Pricing*
                </h1>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 ">
                {/* <PricingCard
                  plan="Starter"
                  price="39"
                  features={featuresBasic}
                /> */}

                <PricingCard
                  plan="Per Developer/ month"
                  //   price="104"
                  features={featuresPremium}
                  bestValue
                />
              </div>
            </div>
          </section>

          {/* Consultant Application Form */}
          <div className="mt-10 sm:mt-0" id="astrology-form-section">
            <div className="md:grid md:grid-cols-2 md:gap-6">
              <div className="md:col-span-1">
                <div className="px-4 sm:px-0">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Project Details
                  </h3>
                  <p className="mt-1 text-sm text-gray-600">
                    Fill out the project details form below.
                  </p>
                </div>
                {showSuccessMessage && (
                  <div
                    className={`fixed top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-green-500 text-white py-2 px-4 rounded-md transition-opacity duration-1000 ${
                      fadeOut ? "opacity-0" : "opacity-100"
                    }`}
                  >
                    Your project has been submitted successfully! We will inform
                    you about the next steps via email .
                  </div>
                )}
              </div>
              <div className="mt-5 md:mt-0 md:col-span-2">
                <form
                  onSubmit={handleSubmit}
                  className="space-y-8 divide-y divide-gray-200 shadow-lg"
                >
                  <div className="shadow sm:rounded-md sm:overflow-hidden">
                    <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                      <div className="grid grid-cols-6 gap-6">
                        {/* Individual Form Fields */}

                        {/* Business Name */}
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="business-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Business Name
                          </label>
                          <input
                            type="text"
                            name="businessName"
                            id="business-name"
                            required
                            value={formData.businessName}
                            onChange={handleInputChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm font-bold border-gray-300 rounded-md"
                          />
                        </div>

                        {/* Business Detail */}
                        <div className="col-span-6">
                          <label
                            htmlFor="business-detail"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {/* Any Custom Question (If You Have) */}
                            Brief Description about Your Business
                          </label>
                          <textarea
                            id="business-detail"
                            name="businessDetail"
                            rows="5"
                            value={formData.businessDetail}
                            required
                            onChange={handleInputChange}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                            placeholder="Enter business details "
                          ></textarea>
                        </div>
                        {/* Business Detail */}

                        {/* Custom Question */}
                        <div className="col-span-6">
                          <label
                            htmlFor="custom-question"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {/* Any Custom Question (If You Have) */}
                            Anything else which might be helpful
                          </label>
                          <textarea
                            id="custom-question"
                            name="customQuestion"
                            rows="3"
                            value={formData.customQuestion}
                            onChange={handleInputChange}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                            placeholder="Anything else which might be helpful"
                          ></textarea>
                        </div>

                        {/* Plan Selection */}
                        {/* <div className="col-span-6">
                          <label
                            htmlFor="plan-selection"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Select a Plan
                          </label>
                          <select
                            id="plan-selection"
                            name="planSelected"
                            required
                            value={formData.planSelected}
                            onChange={handleInputChange}
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                          >
                            <option value="starter">$39 - Starter</option>
                            <option value="pro">$104 - Pro</option>
                          </select>
                        </div> */}
                      </div>

                      {/* Business Partner Details */}
                    </div>
                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                      <button
                        type="submit"
                        disabled={loading}
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-indigo-300"
                      >
                        {loading ? "Submitting..." : "Submit"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {/* End Consultant Application Form */}
        </div>
      </section>

      <section id="faqs">
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          <div className="py-12 md:py-20 border-t border-gray-200">
            {/* Section header */}
            <div className="max-w-3xl mx-auto text-center pb-20">
              <h2 className="h2">FAQ</h2>
            </div>

            {/* Faqs */}
            <ul className="max-w-3xl mx-auto pl-12">
              <Accordion title="Why will i not hire a full time development team?">
                {/* The questions are answered by AI characters created in our service
              with predefined parameters of interests. */}
                A Senior Developer costs nearly $120k-160k/year or
                $12k-$16k/month.
              </Accordion>
              <Accordion title="What tech stack will you use?">
                We primarly use Django, Django Rest Framework,
                Postgresql,Docker,Firebase, Websockets on the backend and React
                and Tailwind CSS for the frontend. Deployments are usually done
                on AWS/GCP.
                {/* Trimming down your research time time from weeks to minutes. */}
                {/*               
              offers rapid, diverse, and data-driven insights without the
              need for extensive market studies. */}
              </Accordion>

              <Accordion title="Who will be the developers?">
                Our senior developers are handpicked who have previously worked
                as Engineering Lead /Senior Software Engineers.
              </Accordion>

              <Accordion title="Do you work on Saturdays or Sundays?">
                Unfortunately we do not work on Saturdays and Sundays.
              </Accordion>

              <Accordion title="Still have questions?" active>
                Contact us at business@feedbackbyai.com or use our chatbox
              </Accordion>

              <span
                className="block border-t border-gray-200"
                aria-hidden="true"
              ></span>
            </ul>
          </div>
        </div>
      </section>
      {/* My Astrology Reports Section */}
      <section className="py-6">
        <div className="container mx-auto px-6 lg:px-8 max-w-5xl rounded-xl">
          <div className="text-center my-6">
            <h1 className="text-4xl font-bold text-gray-900 mb-4">
              Project Status
            </h1>
          </div>
          {userReports.length > 0 ? (
            <div className="space-y-4">
              {userReports.map((report) => (
                <div key={report.id} className="bg-white p-4 rounded shadow">
                  <h2 className="text-xl font-bold">
                    {capitalizeWords(report.business_name)}
                  </h2>
                  {/* <p>Plan: {report.plan_selected}</p> */}
                  <p className="text-gray-700 mb-4">
                    <span className="font-medium ">Status:</span>{" "}
                    {
                      (report.accepted = "False"
                        ? "⏳ Please wait, We will schedule a call soon!"
                        : "Accepted. We will send you more details via email")
                    }
                  </p>
                  {report.pdf && (
                    <a
                      href={report.pdf}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-white hover:text-white btn btn-lg bg-green-600"
                    >
                      View Report
                    </a>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center">
              <p className="text-lg text-gray-600">
                You have not created any projects yet. Start by filling out your
                project details and submitting a request.
              </p>
              <button
                onClick={handleScrollToSection} // Assuming this scrolls to the form
                className="mt-4 btn btn-lg bg-green-600 text-white hover:bg-green-700"
              >
                Submit Request
              </button>
            </div>
          )}
        </div>
      </section>
    </div>
  );
}
