import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

function removeCodeBlockMarkdown(content) {
  return content ? content.replace(/```markdown\n|```/g, "") : "";
}

function LoadingMessage() {
  const [messageIndex, setMessageIndex] = useState(0);

  const loadingMessages = [
    "Fetching the latest insights for you...",
    "Compiling data into something awesome...",
    "Crunching numbers and analyzing trends...",
    "Just a moment, we're tailoring your experience...",
    "Polishing up the details for a perfect presentation...",
    "Almost there, weaving everything together...",
    "Final checks in progress for top-notch accuracy...",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setMessageIndex((prevIndex) => (prevIndex + 1) % loadingMessages.length);
    }, 5000); // Change message every 5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <h3 className="text-lg md:text-2xl font-semibold text-green-600 animate-pulse">
      {loadingMessages[messageIndex]}
    </h3>
  );
}

function SectionDemo({
  sectionTitle,
  sectionNumber,
  reportId,
  id,
  defaultOpen = false,
}) {
  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // const [isOpen, setIsOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(defaultOpen);

  useEffect(() => {
    if (isOpen && !reportData) {
      fetchData();
    }
  }, [isOpen, reportData]);

  const fetchData = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        // `${process.env.REACT_APP_API_BASE_URL}generate_section/?report_id=18&title=${sectionTitle}`
        `${process.env.REACT_APP_API_BASE_URL}generate_demo/?report_id=18&title=${sectionTitle}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setReportData(data);
    } catch (error) {
      setError("Error fetching report data: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <section
      className={`relative ${
        isOpen ? "p-8 md:p-40" : "p-4 md:p-8"
      } bg-white border border-gray-200 rounded-lg shadow-lg mb-4`}
      id={id.toString()}
    >
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={handleToggleOpen}
      >
        <h2
          className={`font-bold text-blue-700 ${
            isOpen ? "text-3xl" : "text-xl"
          } leading-tight mb-2`}
        >
          {sectionNumber}. {sectionTitle}
        </h2>
        <span className="font-bold animate-pulse text-red-600 ">
          {isOpen ? "Click to Collapse" : "Click to Open"}
        </span>
      </div>
      {isOpen &&
        (loading ? (
          <LoadingMessage />
        ) : error ? (
          <div>
            <p>Error: {error}</p>
            <button
              className="mt-2 px-4 py-2 bg-blue-700 text-white rounded"
              onClick={fetchData}
            >
              Retry
            </button>
          </div>
        ) : reportData ? (
          <div className="space-y-7 font-inter antialiased">
            <ReactMarkdown
              className="prose prose-a:text-blue-600  mx-auto leading-normal font-inter antialiased bg-white text-gray-900 tracking-tight"
              remarkPlugins={[remarkGfm]}
            >
              {removeCodeBlockMarkdown(reportData.answer)}
            </ReactMarkdown>
          </div>
        ) : (
          <p>No data available.</p>
        ))}
    </section>
  );
}

export default SectionDemo;
