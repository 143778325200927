import React, { useState } from "react";
import { Link } from "react-router-dom";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../firebaseConfig"; // Ensure this path is correct
import api from "./axiosConfig"; // Adjust the path as necessary
import { FcGoogle } from "react-icons/fc"; // Importing the Google icon

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check if the email domain is beeplush.com
    // Check if the email domain is beeplush.com
    if (email.endsWith("@beeplush.com")) {
      setErrorMsg("This domain has been restricted. Please contact support if you think this is an error.");
      return; // This stops the function here, preventing any further actions
    }

    try {
      const response = await api.post("register/", { email, password });
      if (response.status === 201) {
        localStorage.setItem("access", response.data.access);
        window.location.href = "/dashboard/";
      } else {
        setErrorMsg(response.data.error || "Something went wrong.");
      }
    } catch (error) {
      setErrorMsg(error.response?.data.error || "Something went wrong.");
    }
  };

  const handleGoogleSignUp = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const token = result.user.accessToken;
      const response = await api.post("/google-login/", { token });
      if (response.status === 200) {
        localStorage.setItem("access", response.data.access);
        window.location.href = "/dashboard/";
      } else {
        setErrorMsg(response.data.error || "Something went wrong.");
      }
    } catch (error) {
      setErrorMsg(error.message || "Failed to sign up with Google.");
    }
  };

  return (
    <section className="bg-gradient-to-b from-gray-50 to-green-100 ">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        {/* Adding navigation bar at the top */}
        <nav className=" py-4 mb-8 ">
          <div className="container mx-auto">
            <div className="flex justify-between items-center">
              {/* Navigation text to go to homepage */}
              <Link to="/" className="text-2xl font-bold text-gray-800">
                FeedbackbyAI
              </Link>
              {/* (Add other nav items here if needed in the future) */}
            </div>
          </div>
        </nav>
        <div className="pt-32 pb-12 md:pt-20 md:pb-20">
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-10">
            <h1 className="h1">Signup to FeedbackbyAI</h1>
          </div>

          <div className="max-w-sm mx-auto">
            {/* Google Sign Up Button */}
            <div className="flex flex-wrap -mx-3 mt-3">
              <div className="w-full px-3">
                <button
                  onClick={handleGoogleSignUp}
                  className="flex items-center justify-center text-black bg-white border border-gray-300 hover:bg-gray-100 w-full py-2 px-4 rounded"
                  style={{ boxShadow: "0 2px 4px 0 rgba(0,0,0,0.25)" }}
                >
                  <FcGoogle className="text-2xl mr-3" /> {/* Google Icon */}
                  Sign up with Google
                </button>
              </div>
            </div>

            {/* Divider */}
            <div className="flex items-center my-6">
              <div className="border-t border-gray-300 grow mr-3" aria-hidden="true"></div>
              <div className="text-gray-600 italic">Or</div>
              <div className="border-t border-gray-300 grow ml-3" aria-hidden="true"></div>
            </div>

            <div className="max-w-sm mx-auto">
              {/* <form onSubmit={handleSubmit}>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    {errorMsg && (
                      <p className="text-red-500 text-sm mt-2">{errorMsg}</p>
                    )}
                    <label
                      className="block text-gray-800 text-sm font-medium mb-1"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <input
                      id="email"
                      type="email"
                      className="form-input w-full text-gray-800"
                      placeholder="Enter your email address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <label
                      className="block text-gray-800 text-sm font-medium mb-1"
                      htmlFor="password"
                    >
                      Password
                    </label>
                    <input
                      id="password"
                      type="password"
                      className="form-input w-full text-gray-800"
                      placeholder="Enter your password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <div className="flex justify-between">
                      <div></div>
                      <Link
                        to="/forgot-password"
                        className="text-sm font-medium text-green-600 hover:underline"
                      >
                        Having trouble signing in?
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <div className="flex justify-between">
                      <label className="flex items-center">
                        <input type="checkbox" className="form-checkbox" />
                        <span className="text-gray-600 ml-2">
                          Keep me signed in
                        </span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="flex flex-wrap -mx-3 mt-6">
                  <div className="w-full px-3">
                    <button className="btn text-white bg-green-600 hover:bg-green-700 w-full">
                      Sign up
                    </button>
                  </div>
                </div>
              </form> */}

              {/* <div className="flex items-center my-6">
                <div className="border-t border-gray-300 grow mr-3" aria-hidden="true"></div>
                <div className="text-gray-600 italic">Or</div>
                <div className="border-t border-gray-300 grow ml-3" aria-hidden="true"></div>
              </div> */}

              <div className="text-gray-600 text-center mt-6">
                Already using FeedbackbyAI?{" "}
                <Link to="/signin" className="text-green-600 hover:underline transition duration-150 ease-in-out">
                  Sign in
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignUp;
