import React, { useState, useEffect } from "react";
import DashboardHeader from "./DashboardHeader";
import api from "./axiosConfig";
import axios from "axios";
import RatingModal from "./RatingModal";
import { FaCheckCircle } from "react-icons/fa";

const TopBanner = () => {
  const endTime = new Date();
  endTime.setHours(endTime.getHours() + 8); // Set 24 hours from now

  return (
    <div className="bg-green-700 text-white text-center py-2 px-4">
      {/* <p className="text-sm md:text-base font-normal inline-block mr-2">
        🚀 Special offer for ProductHunt Launch! Use Code{" "}
        <strong>PHLAUNCH</strong> for a 10% discount!
      </p> */}
      <p className="text-xl font-normal inline-block mr-2">
        {/* 🚀 Special offer for New Users! Use Code <strong>YZMJCYNQ</strong> for
        an instant 15% discount! */}
        {/* 🌟 Try our free previews for In-depth reports, mini reports and feedback
        tool 🚀 */}
        {/* 🌟 Introducing Feedback by Astrology on FeedbackbyAI!🚀 Visit{" "} */}
        {/* 🌟 Coming Soon: Personalized, expert advice to reach your business
        milestones. Introducing FeedbackbyAstrology!🚀 For Business Astrology
        Consultation visit{" "} */}
        {/* 🌟 Consultation is coming soon to FeedbackbyAI! */}

        <a className=" " href="/feedback-by-astrology/#astrology-form-section">
          {/* 🌟 First Free Consultation For Our Paid Users With Corporate
          Astrologers. Use Coupon Code "FIRSTASTRO".🚀 */}
          {/* 🌟 Exclusive to Paid Members: Claim Your Free Astrology Consultation
          with Code "FIRSTASTRO" 🚀 */}
          🌟 Limited Time Offer : Claim Your First Corporate Astrology
          Consultation For Free with Code "FIRSTASTRO". Click here 🚀
        </a>
        {/* 🌟 Personal Business Consultation is Coming Soon on FeedbackbyAI! 🚀 */}
        {/* Coming Soon: Get personalized, expert advice to reach your business
        milestone. Stay tuned! */}
        {/* Coming Soon: personalized, expert advice before you reach your
        business milestone. Stay tuned! */}
        {/* Need expert advice before you reach your next business milestone ? Stay
        tuned! */}
        {/* Use Code <strong>YZMJCYNQ</strong> for an
        instant 15% discount! */}
      </p>

      {/* <span className="text-sm md:text-base font-semibold inline-block">
        <CountdownTimer targetDate={endTime} />
      </span> */}
    </div>
  );
};

const Feature = ({ children }) => {
  // Function to selectively style certain phrases
  const styleSpecificPhrases = (text) => {
    if (text.includes("Full")) {
      return <span className="font-bold text-black">{text}</span>;
    } else if (text.includes("Feedbacks")) {
      return <span className="font-bold text-black">{text}</span>;
    } else {
      return text;
    }
  };

  return (
    <li className="flex items-center mb-2">
      <svg
        className="w-3 h-3 fill-current text-green-500 mr-3 shrink-0"
        viewBox="0 0 12 12"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
      </svg>
      <span className="text-gray-700">{styleSpecificPhrases(children)}</span>
    </li>
  );
};

const PricingCard = ({ plan, price, features, bestValue }) => {
  const handleScrollToSection = () => {
    const pricingSection = document.getElementById("pricing");
    if (pricingSection) {
      pricingSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div
      className={`max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden hover:shadow-2xl transition-shadow duration-300 ${
        bestValue ? "border border-green-500" : ""
      }`}
    >
      {bestValue && (
        <div className="text-center py-2 bg-green-100">
          <span className="text-green-600 font-semibold">Best Value Plan!</span>
        </div>
      )}
      <div className="px-4 py-6 sm:px-6 sm:py-8">
        <div className="text-center">
          <span className="text-2xl sm:text-3xl font-bold text-gray-900">
            {plan}
          </span>
          <div className="mt-2">
            <span className="text-xl sm:text-2xl font-semibold text-green-600">
              ${price}
            </span>
          </div>
        </div>
        <hr className="my-6 border-gray-200" />
        <ul className="mb-4 space-y-3">
          {features.map((benefit, idx) => {
            // Check if the benefit contains a number and highlight it
            const isHighlight = /\d+/.test(benefit);
            return (
              <Feature key={idx} highlight={isHighlight}>
                {benefit}
              </Feature>
            );
          })}
        </ul>
      </div>
      <div className="px-4 pt-4 pb-6 bg-gray-50 rounded-b-lg sm:px-6 sm:pt-6 sm:pb-8">
        <button
          className="btn-sm block w-full px-3 py-2 text-xs sm:text-sm font-semibold text-center text-white bg-green-600 hover:bg-green-700"
          onClick={() => {
            document
              .getElementById("astrology-form-section")
              .scrollIntoView({ behavior: "smooth" });
          }}
        >
          Get Started
        </button>
      </div>
    </div>
  );
};

export default function Astrology() {
  const featuresBasic = [
    // "3 Predictions about a Past Event",
    // "1 Custom Question Asked By You",
    "Quick, actionable insight to a critical question",
    // "Best For Yes or No On Your Business Idea",
    // "Best For Follow up Questions",
    // "Detailed Yes or No on Your Business Idea",
    // "1 Custom Question Asked By You",
    // "1 Astrological Suggestion for Your Business Idea ",
    // "Free Preview of Mini Report",
    "1 Page Report as PDF",
  ];

  const featuresPremium = [
    // "3 Predictions about a Past Event",
    // "Detailed Yes or No on Your Business Idea",
    "1 Custom Question Asked By You",
    "Business Partner Compatibility",
    "Business Name,Logo and Branding",
    "Region to Start With",
    "Auspicious day of Launch",

    "Marketing Alignment",
    "Astrological Risk Assessment",
    "Vaastu Compliant Office/Business",

    // "Free Preview of Mini Report",
    "10 Page Report as PDF",
  ];

  const [email, setEmail] = useState("");
  const [userReports, setUserReports] = useState([]);
  const [formData, setFormData] = useState({
    businessName: "",
    dateOfBirth: "",
    timeOfBirth: "",
    placeOfBirth: "",
    customQuestion: "",
    planSelected: "Starter",
    chooseAnumber: "",
    businessDetail: "",
  });
  const [businessPartners, setBusinessPartners] = useState([]);
  const [isRatingModalOpen, setIsRatingModalOpen] = useState(false);
  const [selectedAstrologyId, setSelectedAstrologyId] = useState(null);
  const [selectedReview, setSelectedReview] = useState(null);
  const [reviewStatus, setReviewStatus] = useState({}); // New state to track review status for each report
  const [showTooltip1, setShowTooltip1] = useState(false);
  const [showTooltip2, setShowTooltip2] = useState(false);
  const [userPhoto, setUserPhoto] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [userID, setUserID] = useState(0);
  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const years = Array.from(
    { length: 100 },
    (_, i) => new Date().getFullYear() - i
  );
  const [selectedHour, setSelectedHour] = useState("");
  const [selectedMinute, setSelectedMinute] = useState("");
  const [selectedSecond, setSelectedSecond] = useState("");
  const hours = Array.from({ length: 24 }, (_, i) =>
    i.toString().padStart(2, "0")
  );
  const minutes = Array.from({ length: 60 }, (_, i) =>
    i.toString().padStart(2, "0")
  );
  const seconds = Array.from({ length: 60 }, (_, i) =>
    i.toString().padStart(2, "0")
  );

  // Define fetchUserEmail outside of useEffect so it can be reused
  const fetchUserEmail = async () => {
    const token = localStorage.getItem("access");
    if (!token) {
      alert("Please log in first!");
      return;
    }
    const bearer_token = "Bearer " + token;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}get-user-email/`,
        {
          headers: {
            Authorization: bearer_token,
          },
        }
      );
      const data = response.data;
      setEmail(data.email);
      setUserID(data.id);
    } catch (error) {
      console.error("Error fetching user email:", error);
    }
  };

  // Fetch user email on component mount
  useEffect(() => {
    fetchUserEmail();
  }, []);

  useEffect(() => {
    // Fetch user reports when the component is mounted
    fetchUserReports();
  }, []);

  const fetchUserReports = async () => {
    try {
      const response = await api.get("/user-reports/");
      const reports = response.data;

      // Initialize an object to hold the review status for each report
      const initialReviewStatus = {};

      // Check review status for each report
      const statusChecks = reports.map(async (report) => {
        try {
          const reviewResponse = await api.get(
            `/astrologyreviews/${report.id}/has_reviewed/`
          );
          // Update the initialReviewStatus object
          initialReviewStatus[report.id] = reviewResponse.data.hasReviewed;
        } catch (error) {
          console.error(
            "Error checking review status for report ID:",
            report.id,
            error
          );
          initialReviewStatus[report.id] = false;
        }
      });

      // Wait for all status checks to complete
      await Promise.all(statusChecks);

      // Update the state
      setUserReports(reports);
      setReviewStatus(initialReviewStatus);
    } catch (error) {
      console.error("Error fetching user reports:", error);
    }
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const openRatingModal = async (astrologyId, review) => {
    try {
      const response = await api.get(
        `/astrologyreviews/${astrologyId}/has_reviewed/`
      );
      if (response.data.hasReviewed) {
        setReviewStatus({ ...reviewStatus, [astrologyId]: true });
        alert("You have already rated this consultation.");
      } else {
        setSelectedAstrologyId(astrologyId);
        setSelectedReview(review);
        setIsRatingModalOpen(true);
      }
    } catch (error) {
      console.error("Error checking review status:", error);
      alert("An error occurred while checking the review status.");
    }
  };

  const closeRatingModal = () => {
    setIsRatingModalOpen(false);
    setSelectedAstrologyId(null);
    setSelectedReview(null);
  };

  // Call this function when the review is submitted successfully
  const onReviewSubmit = (astrologyId) => {
    setReviewStatus({ ...reviewStatus, [astrologyId]: true });
  };

  const handleScrollToSection = () => {
    // Assuming the form section has an id of 'astrology-form-section'
    const section = document.getElementById("astrology-form-section");
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  const handleFileChange = (e) => {
    setUserPhoto(e.target.files[0]);
  };

  const handleAddBusinessPartner = () => {
    setBusinessPartners([...businessPartners, { details: "", photo: null }]);
  };

  const handlePartnerChange = (index, e) => {
    const updatedPartners = businessPartners.map((partner, idx) =>
      idx === index ? { ...partner, [e.target.name]: e.target.value } : partner
    );
    setBusinessPartners(updatedPartners);
  };

  const handlePartnerFileChange = (index, e) => {
    const updatedPartners = businessPartners.map((partner, idx) =>
      idx === index ? { ...partner, photo: e.target.files[0] } : partner
    );
    setBusinessPartners(updatedPartners);
  };
  function capitalizeWords(string) {
    return string
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Convert user photo and business partner photos to Base64
    const convertToBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });

    const userPhotoBase64 = userPhoto ? await convertToBase64(userPhoto) : null;
    const partnersPhotosBase64 = await Promise.all(
      businessPartners.map((partner) =>
        partner.photo ? convertToBase64(partner.photo) : null
      )
    );
    const formattedDateOfBirth = `${selectedYear}-${selectedMonth.padStart(
      2,
      "0"
    )}-${selectedDay.padStart(2, "0")}`;
    const formattedTimeOfBirth = `${selectedHour}:${selectedMinute}:${selectedSecond}`;

    // Prepare data for the API
    const data = {
      businessName: formData.businessName,
      customQuestion: formData.customQuestion,
      planSelected: formData.planSelected,
      userPhoto: userPhotoBase64,
      businessDetail: formData.businessDetail,
      user_data: {
        // dateOfBirth: formData.dateOfBirth,
        // timeOfBirth: formData.timeOfBirth,
        dateOfBirth: formattedDateOfBirth,
        timeOfBirth: formattedTimeOfBirth,
        placeOfBirth: formData.placeOfBirth,
        // chooseAnumber: formData.chooseAnumber,
        chooseAnumber: 0,
      },
      businessPartners: businessPartners.map((partner, index) => ({
        ...partner,
        photo: partnersPhotosBase64[index],
      })),
    };

    try {
      const response = await api.post("/astrology/", data);
      console.log("Form submitted successfully:", response.data);

      // Extract the astrology report ID from the response
      const astrologyReportId = response.data.astrologyReportId;

      // Ensure email is fetched and set
      await fetchUserEmail();

      // Log variables
      console.log("Email:", email);
      console.log("Astrology Report ID:", astrologyReportId);
      console.log("Plan Selected:", formData.planSelected);

      // Convert planSelected to lowercase for the condition check
      const planSelectedLower = formData.planSelected.toLowerCase();

      // Use userEmail for redirection
      let redirectUrl = "";
      if (planSelectedLower === "starter") {
        // this is test mode url
        // redirectUrl = `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/60c0a05c-c95f-4138-9205-da2b235ca2c8?checkout[email]=${email}&checkout[custom][report_credits]=0&checkout[custom][question_credits]=0&checkout[custom][mini_report]=0&checkout[custom][type]=astrology&checkout[custom][astroplan]=starter&checkout[custom][astrologyReportId]=${astrologyReportId}`;
        redirectUrl = `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/258181da-43f6-41b3-bc75-fa13a6ff2113?checkout[email]=${email}&checkout[custom][report_credits]=0&checkout[custom][question_credits]=0&checkout[custom][mini_report]=0&checkout[custom][type]=astrology&checkout[custom][astroplan]=starter&checkout[custom][astrologyReportId]=${astrologyReportId}&checkout[custom][userId]=${userID}`;
      } else if (planSelectedLower === "pro") {
        // redirectUrl = `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/01c3f8e8-2ab3-4a6f-a41f-21184fd4cac8?checkout[email]=${email}&checkout[custom][report_credits]=0&checkout[custom][question_credits]=0&checkout[custom][mini_report]=0&checkout[custom][type]=astrology&checkout[custom][astroplan]=pro&checkout[custom][astrologyReportId]=${astrologyReportId}`;
        redirectUrl = `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/5be3988c-d695-4197-a8c5-4e2b610ecf2c?checkout[email]=${email}&checkout[custom][report_credits]=0&checkout[custom][question_credits]=0&checkout[custom][mini_report]=0&checkout[custom][type]=astrology&checkout[custom][astroplan]=pro&checkout[custom][astrologyReportId]=${astrologyReportId}&checkout[custom][userId]=${userID}`;
      }

      console.log("Redirect URL:", redirectUrl); // Check the constructed URL
      if (redirectUrl) {
        window.location.assign(redirectUrl); // Redirect
      } else {
        // Handle cases where redirect URL wasn't constructed properly
        console.error("Redirect URL is not valid.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle form submission errors
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="font-inter antialiased bg-gradient-to-b from-gray-50 to-green-100 text-gray-900 tracking-tight">
      {/* <TopBanner /> */}
      <DashboardHeader />
      <section className="min-h-screen py-6">
        <div className="container mx-auto px-6 lg:px-8 max-w-5xl rounded-xl">
          <div className="text-center my-6">
            <h1 className="text-6xl font-bold text-gray-900 mb-4">
              {/* Start Discovering Relevant Consultants on FeedbackbyAI Soon! */}
              {/* Start Discovering Consultants on FeedbackbyAI Soon! */}
              {/* Consult Expert Business Astrololgers */}
              Corporate Astrology
            </h1>
            <p className="text-2xl text-gray-700 max-w-5xl mx-auto">
              {/* We are excited to bring expert consultants to FeedbackbyAI. If you
              are an industry expert passionate about driving business
              transformation, join us as a consultant and shape the future of
              businesses. */}
              {/* With 2000 new businesses joining FeedbackbyAI every month.We are
              inviting top-tier consultants to help our businesses give 1-1
              advice for every stage of their business journey. */}
              {/* With 27% of people relying on astrology to make business
              decisions. We are trying to leave no stone unturned for your
              business idea validation.We have team up with expert business
              astrologers to help you get astrological perspective on your
              business venture. */}
              {/* Navigate business uncertainities through expert business
              astrolgers suggestion. Bring astrology into play as a business
              strategy. */}
              {/* Navigate business uncertainities through sugestion from astrolgers
              having 20+ years of professional consulation experience. Bring
              astrology into play as part of your business strategy. */}
              {/* Bulletproof your business strategy by  */}
              {/* Business Planning, Business Expansion, Brand Building, */}
              {/* Business Partner Compatibility,Marketing Strategy,Business
              Analysis,Estimated Income, */}
              {/* Success in business is a complex subject, but there are ways to
              let you know: what business, what time, with whom, with what
              resources etc., one should do business. That is where business
              astrology helps. */}
              {/* Consult professional business astrolgers with decades of
              experience in Founder Chart Analysis,Business Partner
              Compatibility, Branding, Risk Assessment,Auspicious Timings of
              Launch , Marketing and Sales Strategy. */}
              {/* Success in business is a complex subject, but there are ways to
              let you know: what business, what time, with whom, with what
              resources etc., one should do business. That is where business
              astrology helps. */}
              {/* In a world of endless choices, making the right business decision
              is crucial. Corporate Astrology provides tailored insights about
              the nature, timing, partnerships, and resource allocation for your
              venture, ensuring you're always a step ahead. */}
              {/* Get highly precise prediction about your professional career. */}
              Check whether you are suited for business or a job. Feedback from
              our top corporate astrologers may exactly help you make the right
              decision.
              {/* with better
              partner,auspicious timings. */}
              {/* with decades of experience. */}
              {/* Know fields that will suit you naturally.
              Launch business in auspicious timings. */}
              {/* Use strength of your natal chart to navigate business
              complexities. */}
              {/* Check whether you are suited for
              business or for job. */}
              {/* Get your D7 chart analyzed from
              professional business astrolgers with decades of professional
              experience. Launch your business with clarity. */}
              {/* Leverage decades of professional business astrology  */}
              {/* Navigate the complexities of business with confidence. Corporate
              Astrology provides tailored insights about the nature, timing,
              partnerships, and resource allocation for your venture, ensuring
              you're always a step ahead. */}
            </p>

            {/* <a
              href="/previewx/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:text-blue-600 underline mt-4 inline-block text-2xl"
            >
              📘 See a Sample Astrology Report
            </a> */}

            {/* Call to Action Button */}
            <button
              onClick={() => {
                document
                  .getElementById("astrology-form-section")
                  .scrollIntoView({ behavior: "smooth" });
              }}
              className="animate-pulse w-full md:w-auto px-5 py-3 text-lg font-bold text-white bg-green-500 rounded-md shadow hover:bg-green-600 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 mt-10"
            >
              {/* Get Started With Your Business Astrology Report */}
              {/* Get Your First Business Consultation Free */}
              {/* Unlock Precise Insights — Fast */}
              {/* Gain Your Competitive Advantage in Just 48 Hours */}
              {/* Get Your First Consultation For Free */}
              Book Your Consultation Now
              {/* Try First Consultation For Free Now */}
              {/* First Consultation Free for Premium Users */}
              {/* Book Now */}
            </button>
            <br />

            <a
              // href="/previewx/"
              // target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:text-blue-600 underline mt-4 inline-block text-2xl"
              onClick={() => {
                document
                  .getElementById("astrology-report-section")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              [ Please note :Your report will be prepared within 48 hours !]
              {/* [ Please note :First Free Consultation is only valid for Starter
              Plan !] */}
            </a>
          </div>
          <section>
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
              <div className="py-12 md:py-16 border-t border-gray-200">
                <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
                  <p className="text-green-800 font-bold text-lg md:text-xl">
                    {/* Less intrusive More relevant */}
                  </p>
                  <h1 className="text-4xl md:text-5xl lg:text-6xl font-extrabold leading-tight mb-4">
                    {/* How it Works <br /> */}
                    {/* but read pinned comment */}
                    {/* A New Era of Business Planning
              Awaits */}
                    How it Works
                  </h1>
                </div>
                {/* Items */}
                <div className="relative max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-none">
                  <div
                    className="absolute top-1/2 w-full h-1 bg-gradient-to-r from-green-800 to-teal-300 hidden lg:block"
                    aria-hidden="true"
                  ></div>

                  {/* 1st item */}
                  <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
                    <div className="flex justify-center items-center h-12 w-12 rounded-full bg-green-800 text-white font-bold mb-3">
                      1
                    </div>
                    <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">
                      Fill in Your Details
                    </h4>
                    <p className="text-gray-600 text-center">
                      {/* Simply type in your brainchild and let our AI take the reins. */}
                      {/* Enter your business idea, target customers and questions you
                want to ask. */}
                      Fill in your business astrology details.
                    </p>
                  </div>

                  {/* 2nd item */}
                  <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
                    <div className="flex justify-center items-center h-12 w-12 rounded-full bg-green-800 text-white font-bold mb-3">
                      2
                    </div>
                    <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">
                      {/* Embrace Your Insights */}
                      Submit Request
                    </h4>
                    <p className="text-gray-600 text-center">
                      {/* Preview the first 3 sections for free and ask up to 3 questions
                with our feedback tool. */}
                      {/* Dive into AI-powered analysis with free previews and questions. */}
                      {/* Next With 1-Click generate your in-depth business report */}
                      Our business astrolgers prepare your report within 48
                      hours.
                    </p>
                  </div>

                  {/* 2nd item */}
                  <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl">
                    <div className="flex justify-center items-center h-12 w-12 rounded-full bg-green-800 text-white font-bold mb-3">
                      3
                    </div>
                    <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">
                      {/* Choose Your Path */}
                      Get Astrology Report
                    </h4>
                    <p className="text-gray-600 text-center">
                      {/* Opt for instant Feedback or a detailed In-Depth Report. */}
                      {/* Choose instant feedback or a comprehensive report. */}
                      {/* Brainstorm with AI Characters as if you are talking to your
                users. */}
                      {/* We email your astrology reports. */}
                      Your reports are visible in the dashboard within 48 hours.
                      {/* Instantly Uncover Concerns About
                Your Business Idea */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            className="bg-gradient-to-b from-gray-50 to-gray-100 py-12"
            id="pricings"
          >
            <div className="container mx-auto px-4">
              <div className="text-center mb-16">
                <h1 className="text-3xl sm:text-4xl font-bold mb-4">Pricing</h1>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-4">
                <PricingCard
                  plan="Starter"
                  price="39"
                  features={featuresBasic}
                />
                <PricingCard
                  plan="Pro"
                  price="104"
                  features={featuresPremium}
                  bestValue
                />
              </div>
            </div>
          </section>

          {/* Consultant Application Form */}
          <div className="mt-10 sm:mt-0" id="astrology-form-section">
            <div className="md:grid md:grid-cols-2 md:gap-6">
              <div className="md:col-span-1">
                <div className="px-4 sm:px-0">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Astro Details
                  </h3>
                  <p className="mt-1 text-sm text-gray-600">
                    Fill out the astro details form below.
                  </p>
                </div>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-2">
                <form
                  onSubmit={handleSubmit}
                  className="space-y-8 divide-y divide-gray-200 shadow-lg"
                >
                  <div className="shadow sm:rounded-md sm:overflow-hidden">
                    <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                      <div className="grid grid-cols-6 gap-6">
                        {/* Individual Form Fields */}

                        {/* Business Name */}
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="business-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Business Name
                          </label>
                          <input
                            type="text"
                            name="businessName"
                            id="business-name"
                            required
                            value={formData.businessName}
                            onChange={handleInputChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>

                        {/* Date of Birth */}
                        {/* Date of Birth Dropdowns */}
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="date-of-birth"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Founder Date of Birth
                          </label>
                          <div className="flex space-x-2">
                            <select
                              id="dob-day"
                              name="dobDay"
                              value={selectedDay}
                              onChange={(e) => setSelectedDay(e.target.value)}
                              className="mt-1 block w-1/3 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                            >
                              <option value="">Day</option>
                              {days.map((day) => (
                                <option key={day} value={day}>
                                  {day}
                                </option>
                              ))}
                            </select>
                            <select
                              id="dob-month"
                              name="dobMonth"
                              value={selectedMonth}
                              onChange={(e) => setSelectedMonth(e.target.value)}
                              className="mt-1 block w-1/3 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                            >
                              <option value="">Month</option>
                              {months.map((month, index) => (
                                <option key={month} value={index + 1}>
                                  {month}
                                </option>
                              ))}
                            </select>
                            <select
                              id="dob-year"
                              name="dobYear"
                              value={selectedYear}
                              onChange={(e) => setSelectedYear(e.target.value)}
                              className="mt-1 block w-1/3 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                            >
                              <option value="">Year</option>
                              {years.map((year) => (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        {/* Time of Birth Dropdowns */}
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="time-of-birth"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Founder Time of Birth
                          </label>
                          <div className="flex space-x-2">
                            <select
                              id="time-hour"
                              name="timeHour"
                              value={selectedHour}
                              onChange={(e) => setSelectedHour(e.target.value)}
                              className="mt-1 block w-1/3 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                            >
                              <option value="">HH</option>
                              {hours.map((hour) => (
                                <option key={hour} value={hour}>
                                  {hour}
                                </option>
                              ))}
                            </select>
                            <select
                              id="time-minute"
                              name="timeMinute"
                              value={selectedMinute}
                              onChange={(e) =>
                                setSelectedMinute(e.target.value)
                              }
                              className="mt-1 block w-1/3 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                            >
                              <option value="">MM</option>
                              {minutes.map((minute) => (
                                <option key={minute} value={minute}>
                                  {minute}
                                </option>
                              ))}
                            </select>
                            <select
                              id="time-second"
                              name="timeSecond"
                              value={selectedSecond}
                              onChange={(e) =>
                                setSelectedSecond(e.target.value)
                              }
                              className="mt-1 block w-1/3 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                            >
                              <option value="">SS</option>
                              {seconds.map((second) => (
                                <option key={second} value={second}>
                                  {second}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        {/* Place of Birth */}
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="place-of-birth"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Founder Place of Birth
                          </label>
                          <input
                            type="text"
                            name="placeOfBirth"
                            id="place-of-birth"
                            required
                            value={formData.placeOfBirth}
                            onChange={handleInputChange}
                            placeholder="City, State, Country eg: Austin, Texas, United States"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>

                        {/* Choose a Number */}
                        {/* <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="choose-a-number"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Choose a number between 1 and 249
                          </label>
                          <input
                            type="text"
                            name="chooseAnumber"
                            id="choose-a-number"
                            value={formData.chooseAnumber}
                            onChange={handleInputChange}
                            placeholder="eg 34"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          />
                        </div> */}

                        {/* User Photo */}
                        {/* <div className="col-span-6">
                          <label
                            htmlFor="user-photo"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Upload Your Photo (Ensure your face is clearly
                            visible)
                          </label>
                          <input
                            id="user-photo"
                            name="userPhoto"
                            type="file"
                            onChange={handleFileChange}
                            className="mt-1 block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                          />
                        </div> */}

                        {/* Business Detail */}
                        <div className="col-span-6">
                          <label
                            htmlFor="business-detail"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {/* Any Custom Question (If You Have) */}
                            Business detail in 100 words
                          </label>
                          <textarea
                            id="business-detail"
                            name="businessDetail"
                            rows="3"
                            value={formData.businessDetail}
                            required
                            onChange={handleInputChange}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                            placeholder="Enter business details "
                          ></textarea>
                        </div>
                        {/* Business Detail */}

                        {/* Custom Question */}
                        <div className="col-span-6">
                          <label
                            htmlFor="custom-question"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {/* Any Custom Question (If You Have) */}
                            {/* Any business specific question you have */}
                            Your Question
                          </label>
                          <textarea
                            id="custom-question"
                            name="customQuestion"
                            rows="3"
                            value={formData.customQuestion}
                            onChange={handleInputChange}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                            placeholder="Ask Your question here"
                          ></textarea>
                        </div>

                        {/* Plan Selection */}
                        <div className="col-span-6">
                          <label
                            htmlFor="plan-selection"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Select a Plan (*First Consultation is Free Only For
                            $39 Starter Plan)
                          </label>
                          <select
                            id="plan-selection"
                            name="planSelected"
                            required
                            value={formData.planSelected}
                            onChange={handleInputChange}
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                          >
                            <option value="starter">$39 - Starter</option>
                            <option value="pro">$104 - Pro</option>
                          </select>
                        </div>
                      </div>

                      {/* Business Partner Details */}
                      <div className="mt-4">
                        <label className="block text-sm font-medium text-gray-700">
                          Business Cofounders/Partners details if any ( eg: Name
                          ,Date of birth, Time of birth , Place Of Birth)
                        </label>
                        {businessPartners.map((partner, index) => (
                          <div key={index} className="mt-4">
                            <textarea
                              name="details"
                              rows="3"
                              value={partner.details}
                              onChange={(e) => handlePartnerChange(index, e)}
                              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                              placeholder="Lila Archer, 08/23/1993, 07:23 PM, Manchester, Greater Manchester, United Kingdom"
                            ></textarea>
                            {/* <input
                              type="file"
                              name="photo"
                              onChange={(e) =>
                                handlePartnerFileChange(index, e)
                              }
                              className="mt-2 block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer focus:outline-none"
                            /> */}
                          </div>
                        ))}
                        <button
                          type="button"
                          onClick={handleAddBusinessPartner}
                          className="mt-2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-indigo-700"
                        >
                          Add Business Partner
                        </button>
                      </div>
                    </div>
                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                      <button
                        type="submit"
                        disabled={loading}
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-indigo-300"
                      >
                        {loading ? "Submitting..." : "Proceed to Payment"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {/* End Consultant Application Form */}
        </div>
      </section>
      {/* My Astrology Reports Section */}
      <section className="py-6" id="astrology-report-section">
        <div className="container mx-auto px-6 lg:px-8 max-w-5xl rounded-xl">
          <div className="text-center my-6">
            <h1 className="text-5xl font-bold text-gray-900 mb-4">
              My Astrology Reports
            </h1>
          </div>
          {/* Add the Rating Modal */}
          <RatingModal
            isOpen={isRatingModalOpen}
            onClose={closeRatingModal}
            astrologyId={selectedAstrologyId}
            existingReview={selectedReview}
          />

          {userReports.length > 0 ? (
            <div className="space-y-4">
              {userReports.map((report) => (
                <div key={report.id} className="bg-white p-4 rounded shadow">
                  <h2 className="text-xl font-bold">
                    {capitalizeWords(report.business_name)}
                  </h2>
                  <p className="text-gray-700 mb-4">
                    <span className="font-medium">Status:</span>{" "}
                    {report.status === "Consultation In-Progress"
                      ? "⏳ Your report is being prepared and will be ready within 48 hours!"
                      : report.status}
                  </p>
                  {report.pdf && (
                    <>
                      <a
                        href={report.pdf}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-white hover:text-white btn btn-lg bg-green-600 mr-2"
                      >
                        View Report
                      </a>
                      {reviewStatus[report.id] ? (
                        <div className="text-green-600 flex items-center">
                          <FaCheckCircle className="mr-2" />
                          Review Submitted
                        </div>
                      ) : (
                        <button
                          onClick={() =>
                            openRatingModal(report.id, report.review)
                          }
                          className="btn btn-primary bg-green-600  hover:bg-green-600 text-white"
                        >
                          Rate Consultation
                        </button>
                      )}
                    </>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center">
              <p className="text-lg text-gray-600">
                You have not created any astrology reports yet. Start by filling
                out your astrology details and submitting a request.
              </p>
              <button
                onClick={handleScrollToSection}
                className="mt-4 btn btn-lg bg-green-600 text-white hover:bg-green-700"
              >
                Create Astrology Report
              </button>
            </div>
          )}
        </div>
      </section>
    </div>
  );
}
