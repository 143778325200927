import { useState, useEffect } from "react";
import React from "react";

const CountdownTimer = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents = Object.keys(timeLeft).map((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    return (
      <span key={interval}>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  return (
    <div>
      {timerComponents.length ? timerComponents : <span>Time's up!</span>}
    </div>
  );
};

const TopBanner = () => {
  const endTime = new Date();
  endTime.setHours(endTime.getHours() + 8); // Set 24 hours from now

  return (
    <div className="bg-green-700 text-white text-center py-2 px-4">
      {/* <p className="text-sm md:text-base font-normal inline-block mr-2">
        🚀 Special offer for ProductHunt Launch! Use Code{" "}
        <strong>PHLAUNCH</strong> for a 10% discount!
      </p> */}
      <p className="text-sm md:text-base font-normal inline-block mr-2">
        {/* 🚀 Special offer for New Users! Use Code <strong>YZMJCYNQ</strong> for
        an instant 15% discount! */}
        {/* 🌟 New Feature Alert: Mini-Reports Now Available! Get a concise 10-page
        analysis for only $9.99. Additionally, we've expanded our Full Report
        with 6 Brand New Sections 🚀 */}
        🌟 Try our free previews for In-depth reports, mini reports and feedback
        tool 🚀
        {/* Use Code <strong>YZMJCYNQ</strong> for an
        instant 15% discount! */}
      </p>

      {/* <span className="text-sm md:text-base font-semibold inline-block">
        <CountdownTimer targetDate={endTime} />
      </span> */}
    </div>
  );
};

export default TopBanner;
