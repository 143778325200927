import axios from "axios";

const instance = axios.create({
  // baseURL: "http://127.0.0.1:8000/",
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

// Set the JWT token for every request if it exists
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Handle 401 Unauthorized responses
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = "/signin/";
    }
    return Promise.reject(error);
  }
);

export default instance;
