// import React, { useState, useEffect } from "react";
// // import MobileMenu from "../components/Mobile-menu";
// import MobileMenuDemo from "./MobileMenuDemo";
// import { Link, useParams } from "react-router-dom";
// import LogoutButton from "./LogoutButton";
// import LogoutButtonDemo from "./LogoutButtonDemo";
// import api from "./axiosConfig"; // Adjust the path as necessary
// import Logo from "../images/thumbnail.png";

// export default function ReportDemoNavbar({ purchased }) {
//   const [user, setUser] = useState(null);
//   const [top, setTop] = useState(true);
//   const { reportId } = useParams();
//   const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);

//   // detect whether user has scrolled the page down by 10px
//   const scrollHandler = () => {
//     window.pageYOffset > 10 ? setTop(false) : setTop(true);
//   };

//   const saveReportAsPDF = () => {
//     // URL of the PDF to be opened
//     const pdfUrl =
//       // "https://feedbackbyaibe-production.s3.amazonaws.com/media/reports/pdfs/report_2_6ANlPL1.pdf";
//       "https://feedbackbyaibe-production.s3.amazonaws.com/media/reports/pdfs/report_18.pdf";

//     // Open a new tab with the PDF URL
//     window.open(pdfUrl, "_blank");
//   };

//   return (
//     <header
//       className={`top w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out ${
//         !top ? "bg-white backdrop-blur-sm shadow-lg" : ""
//       }`}
//     >
//       <div className="max-w-6xl mx-auto px-5 sm:px-6">
//         <div className="flex items-center justify-between h-12 md:h-12">
//           {/* Site branding */}
//           <div className="shrink-0 mr-4">
//             <Link
//               to="/"
//               className="flex items-center text-2xl font-bold text-gray-800"
//             >
//               <img src={Logo} alt="Logo" className="h-10 mr-2" />
//               <span className="font-bold text-green-600 h-8">FeedbackbyAI</span>
//             </Link>
//           </div>

//           {/* Desktop navigation */}
//           <nav className="hidden md:flex md:grow">
//             {/* Desktop menu links */}
//             <ul className="flex grow justify-end flex-wrap items-center">
//               <li>
//                 <a
//                   href="/dashboard/"
//                   className="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out"
//                 >
//                   Instant Feedback
//                 </a>
//               </li>

//               <li>
//                 <a
//                   href="/report/"
//                   className="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out"
//                 >
//                   In-depth Analysis
//                 </a>
//               </li>

//               <li>
//                 <a
//                   href="/credits/"
//                   className="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out"
//                 >
//                   Credits & Recharge
//                 </a>
//               </li>

//               <li>
//                 <a
//                   href="mailto:business@feedbackbyai.com" // This will open the user's default mail client with your email address
//                   className="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out"
//                 >
//                   Contact Us
//                 </a>
//               </li>

//               {/* <li>
//                 {purchased ? (
//                   isGeneratingPDF ? (
//                     <span>PDF preparation is underway, please wait...</span>
//                   ) : (
//                     <li
//                       className="mr-4 cursor-pointer font-bold"
//                       onClick={saveReportAsPDF}
//                     >
//                       Save as PDF
//                     </li>
//                   )
//                 ) : null}
//               </li> */}
//             </ul>

//             {/* Desktop sign in links */}
//             <ul className="flex grow justify-end flex-wrap items-center">
//               <li>
//                 <a
//                   href="/signin/"
//                   className="btn btn-4xl text-gray-200  bg-green-600 hover:bg-green-700 ml-3"
//                 >
//                   SignIn
//                 </a>
//               </li>
//             </ul>
//           </nav>

//           <MobileMenuDemo />
//         </div>
//       </div>
//     </header>
//   );
// }

import React, { useState, useEffect } from "react";
import { NavLink, Link, useParams } from "react-router-dom";
import { Menu, X } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import Logo from "../images/thumbnail.png";
import LogoutButton from "./LogoutButton";
import api from "./axiosConfig"; // Adjust the path as necessary

const NavItem = ({ to, children }) => (
  <NavLink
    to={to}
    className={({ isActive }) =>
      `px-4 py-2 rounded-full text-sm font-medium transition-all duration-200 ${
        isActive ? "bg-green-100 text-green-800" : "text-gray-600 hover:bg-green-50 hover:text-green-700"
      }`
    }
  >
    {children}
  </NavLink>
);

const MobileNavItem = ({ to, children, onClick }) => (
  <NavLink
    to={to}
    onClick={onClick}
    className={({ isActive }) =>
      `block px-4 py-2 text-base font-medium transition-colors duration-200 ${
        isActive ? "bg-green-100 text-green-800" : "text-gray-600 hover:bg-green-50 hover:text-green-700"
      }`
    }
  >
    {children}
  </NavLink>
);

export default function ReportDemoNavbar({ purchased }) {
  const [user, setUser] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const { reportId } = useParams();
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);

  const saveReportAsPDF = () => {
    const pdfUrl = "https://feedbackbyaibe-production.s3.amazonaws.com/media/reports/pdfs/report_18.pdf";
    window.open(pdfUrl, "_blank");
  };

  return (
    <header className="bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-4 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <NavLink to="/dashboard/" className="flex items-center">
              <img className="h-8 w-auto sm:h-10" src={Logo} alt="FeedbackbyAI" />
              <span className="ml-2 text-xl font-bold text-green-600">FeedbackbyAI</span>
            </NavLink>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <button
              type="button"
              className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500"
              onClick={() => setIsOpen(!isOpen)}
            >
              <span className="sr-only">Open menu</span>
              <Menu className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <nav className="hidden md:flex space-x-10">
            <NavItem to="/feedback/"> AI Character Feedback</NavItem>
            <NavItem to="/dashboard/"> 100+ Page Report</NavItem>
            <NavItem to="/credits/">Credits & Recharge</NavItem>
            <NavItem to="/contact/">Contact Us</NavItem>
            <NavItem to="/signin/">SignIn</NavItem>
          </nav>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            <a
              href="mailto:business@feedbackbyai.com"
              className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-full shadow-sm text-base font-medium text-white bg-green-600 hover:bg-green-700"
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
          >
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div>
                    <img className="h-8 w-auto" src={Logo} alt="FeedbackbyAI" />
                  </div>
                  <div className="-mr-2">
                    <button
                      type="button"
                      className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500"
                      onClick={() => setIsOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <X className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </div>
                <div className="mt-6">
                  <nav className="grid gap-y-8">
                    <MobileNavItem to="/dashboard/" onClick={() => setIsOpen(false)}>
                      AI Character Feedback
                    </MobileNavItem>
                    <MobileNavItem to="/dashboard/" onClick={() => setIsOpen(false)}>
                      100+ Page Report
                    </MobileNavItem>
                    <MobileNavItem to="/credits/" onClick={() => setIsOpen(false)}>
                      Credits & Recharge
                    </MobileNavItem>
                    <MobileNavItem href="mailto:business@feedbackbyai.com" onClick={() => setIsOpen(false)}>
                      Contact Us
                    </MobileNavItem>
                    {purchased && (
                      <MobileNavItem onClick={saveReportAsPDF}>{isGeneratingPDF ? "Generating PDF..." : "Save as PDF"}</MobileNavItem>
                    )}
                  </nav>
                </div>
              </div>
              <div className="py-6 px-5 space-y-6">
                <div>
                  {user && (
                    <div className="mt-6">
                      <LogoutButton setUser={setUser} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
}
