import React, { useState, useEffect } from "react";
import tableOfContentData from "./TableofContentDemo.json"; // Adjust the path as necessary

function TableOfContent({ currentSection, isPurchased, language }) {
  const [translations, setTranslations] = useState(null);
  const shouldShowLock = (contentText) => {
    const noLockNeeded = ["Executive Summary", "The Idea"];
    return !isPurchased && !noLockNeeded.includes(contentText);
  };

  useEffect(() => {
    // Dynamically import the translation file based on the current language

    import(`./translations/translations_${language}.json`)
      .then((module) => {
        setTranslations(module.default);
      })
      .catch((error) => console.error(`Failed to load translations: ${error}`));
  }, [language]); // Re-import if language changes

  if (!translations) {
    return <div>Loading translations...</div>;
  }

  return (
    <section className="relative pt-20 bg-gray-100 p-4 rounded-lg shadow-md h-screen sticky top-0 overflow-y-auto">
      <h2 className="font-extrabold text-2xl mb-4 border-b pb-2">
        {translations.tableOfContents}
      </h2>

      <ol className="list-decimal pl-5 mt-4">
        {translations.sections.map((section, idx) => (
          <li
            key={idx}
            className={`mt-2 ${
              currentSection === idx + 1 ? "text-blue-600" : "text-gray-800"
            }`}
          >
            {section.number}. {section.name}
            {shouldShowLock(section.name) && (
              <span className="ml-2 text-red-500">🔒</span>
            )}
            <ol className="list-decimal pl-5 mt-2">
              {section.content.map((content, cIdx) => (
                <li key={cIdx}>
                  <a
                    href={`#${content.number}`}
                    className={`text-sm ${
                      !isPurchased ? "text-gray-700" : "text-gray-700"
                    } underline`}
                  >
                    {`${content.number}. ${content.text}`}
                    {shouldShowLock(content.text) && (
                      <span className="ml-1 text-red-500">🔒</span>
                    )}
                  </a>
                </li>
              ))}
            </ol>
          </li>
        ))}
      </ol>
    </section>
  );
}

export default TableOfContent;
