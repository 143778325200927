// index.js
import React from "react";
import ReactDOM from "react-dom";
import "../src/css/style.css";
import App from "./App";
// import ReactGA from "react-ga";
// import ReactGA from "react-ga4";
import { GoogleOAuthProvider } from "@react-oauth/google";

// Initialize Google Analytics with your tracking ID
// ReactGA.initialize("G-2N7RPC9EJH"); // Replace with your actual Google Analytics Tracking ID

ReactDOM.render(
  <GoogleOAuthProvider clientId="26849349697-88plip74v9p8sugr9q42p7goa6l5o80l.apps.googleusercontent.com">
    <React.StrictMode>
      <App />
    </React.StrictMode>
    ,
  </GoogleOAuthProvider>,
  document.getElementById("root")
);
