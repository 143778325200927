import React, { useState, useEffect } from "react";
import api from "./axiosConfig";

const Star = ({ selected, onSelect }) => (
  <span
    className={selected ? "text-yellow-500" : "text-gray-400"}
    onClick={onSelect}
  >
    &#9733;
  </span>
);

const RatingModal = ({ isOpen, onClose, astrologyId, existingReview }) => {
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState(false); // New state for submission status

  useEffect(() => {
    if (existingReview) {
      setRating(existingReview.rating);
      setReview(existingReview.review);
    }
  }, [existingReview]);

  const submitReview = async () => {
    setIsSubmitting(true);
    try {
      const response = await api.post("/astrologyreviews/", {
        astrology: astrologyId,
        rating: rating,
        review: review,
      });
      console.log("Review submitted:", response.data);
      setSubmissionSuccess(true); // Set submission success to true
      setTimeout(() => {
        onClose(); // Close the modal after a delay
        setSubmissionSuccess(false); // Reset submission success state
      }, 2000); // Delay of 2 seconds
    } catch (error) {
      console.error("Error submitting review:", error);
      setSubmissionSuccess(false); // In case of error, reset submission success state
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
      <div className="relative p-5 border w-96 shadow-lg rounded-md bg-white">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-700 text-lg leading-none"
        >
          &#x2715;{" "}
          {/* This is the Unicode character for a multiplication sign (X) used as a close icon */}
        </button>

        <div className="text-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Rate Consultation
          </h3>
          <div className="mt-2 flex justify-center">
            {[1, 2, 3, 4, 5].map((star) => (
              <Star
                key={star}
                selected={star <= rating}
                onSelect={() => setRating(star)}
              />
            ))}
          </div>
          <textarea
            className="mt-2 p-2 border rounded-md w-full"
            rows="4"
            placeholder="Write your review here..."
            value={review}
            onChange={(e) => setReview(e.target.value)}
          />
          {submissionSuccess ? (
            <div className="text-green-500 mt-2">
              Rating submitted successfully!
            </div>
          ) : (
            <button
              className="mt-4 px-4 py-2 bg-green-500 text-white text-base font-medium rounded-md shadow-sm hover:bg-green-700 focus:outline-none"
              onClick={submitReview}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Submit Review"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default RatingModal;
