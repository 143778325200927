// import React from "react";
// // import Logo from "../components/Logo"; // Make sure to import the correct path for your Logo component
// import { Link } from "react-router-dom";

// export default function ReportFooter() {
//   return (
//     <footer>
//       <div className="max-w-6xl mx-auto px-4 sm:px-6">
//         {/* Top area: Blocks */}

//         {/* Bottom area */}
//         <div className="md:flex md:items-center md:justify-between py-4 md:py-8 ">
//           {/* Social links */}
//           <ul className="flex mb-4 md:order-1 md:ml-4 md:mb-0">
//             <li>
//               <Link
//                 to="https://feedbackbyai1.lemonsqueezy.com/affiliates/"
//                 className="flex justify-center items-center text-gray-600 hover:text-gray-900hover:bg-white-100 rounded-full  transition duration-150 ease-in-out ml-5"
//               >
//                 Affliate Program
//               </Link>
//             </li>

//             <li>
//               <Link
//                 to="/terms/"
//                 className="flex justify-center items-center text-gray-600 hover:text-gray-900hover:bg-white-100 rounded-full  transition duration-150 ease-in-out ml-5"
//               >
//                 Terms
//               </Link>
//             </li>

//             <li>
//               <Link
//                 to="/privacy/"
//                 className="flex justify-center items-center text-gray-600 hover:text-gray-900 hover:bg-white-100 rounded-full  transition duration-150 ease-in-out ml-5 "
//               >
//                 Privacy
//               </Link>
//             </li>

//             <li>
//               <Link
//                 to="mailto:business@feedbackbyai.com"
//                 className="flex justify-center items-center text-gray-600 hover:text-gray-900  hover:bg-white-100 rounded-full  transition duration-150 ease-in-out ml-5 "
//               >
//                 Support
//               </Link>
//             </li>

//             <li>
//               <Link
//                 to="https://twitter.com/feedbackbyai"
//                 className="flex justify-center items-center text-gray-600 hover:text-gray-900  hover:bg-white-100 rounded-full  transition duration-150 ease-in-out ml-5 "
//               >
//                 Twitter
//               </Link>
//             </li>

//             {/* Add other social media links */}
//           </ul>

//           {/* Copyrights note */}
//           <div className="text-sm text-gray-600 mr-4">
//             © 2024 FeedbackbyAI. All rights reserved.
//           </div>
//         </div>
//       </div>
//     </footer>
//   );
// }

import React from "react";
import { Link } from "react-router-dom";

const FooterLink = ({ to, children }) => (
  <li>
    <Link to={to} className="text-sm text-gray-600 hover:text-green-600 transition-colors duration-300 px-3 py-2">
      {children}
    </Link>
  </li>
);

export default function ReportFooter() {
  return (
    <footer className="bg-gray-50 border-t border-gray-200">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-8 md:py-12">
          <div className="flex flex-col md:flex-row justify-between items-center">
            {/* Links */}
            <ul className="flex flex-wrap justify-center md:justify-start mb-6 md:mb-0">
              <FooterLink to="https://feedbackbyai1.lemonsqueezy.com/affiliates/">Affiliate Program</FooterLink>
              <FooterLink to="/terms/">Terms</FooterLink>
              <FooterLink to="/privacy/">Privacy</FooterLink>
              <FooterLink to="mailto:business@feedbackbyai.com">Support</FooterLink>
              <FooterLink to="https://twitter.com/feedbackbyai">Twitter</FooterLink>
              <FooterLink to="https://needfinderai.com">NeedFinderAI</FooterLink>
            </ul>

            {/* Copyrights note */}
            <div className="text-sm text-gray-600">© {new Date().getFullYear()} FeedbackbyAI. All rights reserved.</div>
          </div>
        </div>
      </div>
    </footer>
  );
}
