// import React from "react";
// import Accordion from "../components/Accordion";

// export default function Faqs() {
//   return (
//     <section id="faqs">
//       <div className="max-w-6xl mx-auto px-4 sm:px-6">
//         <div className="py-12 md:py-20 border-t border-gray-200">
//           {/* Section header */}
//           <div className="max-w-3xl mx-auto text-center pb-20">
//             <h2 className="h2">Questions & Answers</h2>
//           </div>

//           {/* Faqs */}
//           <ul className="max-w-3xl mx-auto pl-12">
//             <Accordion title="What is FeedbackbyAI?">
//               {/* The questions are answered by AI characters created in our service
//               with predefined parameters of interests. */}
//               An AI-driven research platform helping business save months of
//               research hours on idea validation and planning.
//             </Accordion>
//             <Accordion title="How does it differ from traditional research?">
//               It uses advanced AI to do all the number crunching and research
//               for you eliminating extensive market studies.
//               {/* Trimming down your research time time from weeks to minutes. */}
//               {/*
//               offers rapid, diverse, and data-driven insights without the
//               need for extensive market studies. */}
//             </Accordion>
//             <Accordion title="Can i get report in a specific language?">
//               Yes Absolutely. We support English, Spanish, French, Arabic,
//               Russian, Portuguese, Indonesian, German, Japanese, Swahili,
//               Turkish, Korean, Italian, Vietnamese, Polish, Ukrainian, Dutch,
//               Greek, Hebrew, Thai, Persian, Czech, Swedish, Romanian, Hungarian,
//               Danish, Finnish, Slovak, Norwegian, Serbian, Bulgarian, Mandarin.
//               {/* Trimming down your research time time from weeks to minutes. */}
//               {/*
//               offers rapid, diverse, and data-driven insights without the
//               need for extensive market studies. */}
//             </Accordion>

//             <Accordion title="Can i get report for a specific market?">
//               Yes Absolutely. Just enter your business idea with the location
//               you are operating.
//               {/* Trimming down your research time time from weeks to minutes. */}
//               {/*
//               offers rapid, diverse, and data-driven insights without the
//               need for extensive market studies. */}
//             </Accordion>
//             <Accordion title="Is my business idea kept confidential?">
//               Absolutely. We value your privacy.Your data is not sold,shared or
//               distributed with third parties. You have option to request data
//               deletion at any time.
//               {/* Trimming down your research time time from weeks to minutes. */}
//               {/*
//               offers rapid, diverse, and data-driven insights without the
//               need for extensive market studies. */}
//             </Accordion>

//             {/*
//             <Accordion title="What if i am not happy with the reports?">
//               You can issue a 100% refund within 24 hours of purchase,provided
//               you have not downloaded our pdf. But we are sure you will
//               definitely like the results. */}
//             {/* Trimming down your research time time from weeks to minutes. */}
//             {/*
//               offers rapid, diverse, and data-driven insights without the
//               need for extensive market studies. */}
//             {/* </Accordion> */}

//             {/* <Accordion title="What is an AI Character?">
//               An AI character is a virtual character created from a composite of
//               real people. It forms an opinion based on the predefined interests
//               for a specific character in the service, as well as based on the
//               logic, statistics, and historical data. Therefore, the character's
//               answers are as close as possible to the answers of real people.
//             </Accordion> */}

//             {/* <Accordion title="What is an AI Character?">
//               An AI character is a virtual character created from a composite of
//               real people. It forms an opinion based on the predefined interests
//               for a specific character in the service, as well as based on the
//               logic, statistics, and historical data. Therefore, the character's
//               answers are as close as possible to the answers of real people.
//             </Accordion> */}
//             {/* <Accordion title="What AI Characters are available in the service?">
//               The service has more than 51+ AI characters. All of them resemble
//               humans we meet in real life.You can learn about all the characters
//               by signing up.
//             </Accordion> */}

//             {/* <Accordion title="Can I view sample full business report?" active>
//               <a href="/previewx/" className="bg-green-300">
//                 Absolutely.You can read it here <span className="ml-1">👉</span>
//               </a>
//               . For a more tailored analysis please signup for free preview. */}
//             {/* Trimming down your research time time from weeks to minutes. */}
//             {/*
//               offers rapid, diverse, and data-driven insights without the
//               need for extensive market studies. */}
//             {/* </Accordion> */}

//             {/* <Accordion title="Can I view sample mini report?" active>
//               <a href="/minipreview/" className="bg-green-300">
//                 Yes you can preview a sample mini report here{" "}
//                 <span className="ml-1">👉</span>
//               </a> */}
//             {/* Trimming down your research time time from weeks to minutes. */}
//             {/*
//               offers rapid, diverse, and data-driven insights without the
//               need for extensive market studies. */}
//             {/* </Accordion> */}

//             {/* <Accordion title="What if i don't find your reports/feedbacks useful?"> */}
//             {/* Yes, but currently it's a private feature. Please email us for
//               more information - business@feedbackbyai.com */}
//             {/* Yes! Choose characters, questions, and regions to tailor your
//               feedback journey. */}
//             {/* In that case we will issue 100% refund within 24 hours of purchase
//               . No questions asked.Email us at business@feedbackbyai.com */}
//             {/* </Accordion> */}

//             <Accordion title="Can I customize my  report / AI experts experience?">
//               {/* Yes, but currently it's a private feature. Please email us for
//               more information - business@feedbackbyai.com */}
//               {/* Yes! Choose characters, questions, and regions to tailor your
//               feedback journey. */}
//               Yes experts can be added to suit your business . But it does
//               charge extra.Email us at business@feedbackbyai.com
//             </Accordion>

//             {/* <Accordion
//               title="Does validation replace the opinion of real person?"
//               active
//             >
//               AI characters provide answers based on logic, statistics, and
//               historical data, which imitate real human answers. However, AI
//               characters lack personal experience, intuition, feelings, and
//               moral convictions that can also influence the final
//               decision-making of real people. Thus, an AI character can assist
//               in forming an opinion about your idea, but it cannot fully replace
//               the opinion and viewpoint of a real person.
//             </Accordion> */}

//             <Accordion title="Still have questions?" active>
//               Contact us at business@feedbackbyai.com or use our chatbox
//             </Accordion>

//             <span
//               className="block border-t border-gray-200"
//               aria-hidden="true"
//             ></span>
//           </ul>
//         </div>
//       </div>
//     </section>
//   );
// }

import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { HiChevronDown, HiOutlineMail, HiOutlineChat } from "react-icons/hi";

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200 py-4">
      <button className="flex justify-between items-center w-full text-left" onClick={() => setIsOpen(!isOpen)}>
        <span className="text-lg font-medium text-gray-900">{question}</span>
        <HiChevronDown className={`w-5 h-5 text-gray-500 transform transition-transform ${isOpen ? "rotate-180" : ""}`} />
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <p className="mt-2 text-gray-600">{answer}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default function Faqs() {
  const faqData = [
    {
      question: "What is FeedbackbyAI?",
      answer:
        "FeedbackbyAI is an AI-powered platform that revolutionizes business research. We help entrepreneurs and businesses validate ideas and create comprehensive business plans in minutes, saving months of traditional research time.",
    },
    {
      question: "How does it compare to traditional research methods?",
      answer:
        "Our advanced AI technology condenses months of market research into minutes. It analyzes vast amounts of data to provide you with actionable insights, eliminating the need for time-consuming manual research and expensive market studies.",
    },
    {
      question: "Can I get reports in multiple languages?",
      answer:
        "Absolutely! We support over 30 languages, including English, Spanish, French, German, Japanese, and many more. Simply select your preferred language when generating your report.",
    },
    {
      question: "Is it possible to get market-specific reports?",
      answer:
        "Yes, you can. When entering your business idea, specify your target location or market. Our AI will tailor the report to that specific region or industry.",
    },
    {
      question: "How do you ensure the confidentiality of my business idea?",
      answer:
        "We take your privacy seriously. Your data is encrypted and never shared with third parties. We offer a data deletion option, giving you full control over your information at all times.",
    },
    {
      question: "Can I customize my report or AI expert experience?",
      answer:
        "Yes, we offer customization options. For specialized AI experts or tailored reports, please contact us at business@feedbackbyai.com. Note that custom features may incur additional charges.",
    },
  ];

  return (
    <section className="bg-gray-50 py-20" id="faqs">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">Frequently Asked Questions</h2>
        <div className="bg-white rounded-2xl shadow-lg p-8">
          {faqData.map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>
        <div className="mt-12 text-center">
          <p className="text-lg text-gray-600 mb-4">Still have questions?</p>
          <div className="flex justify-center space-x-4">
            <a href="mailto:business@feedbackbyai.com" className="flex items-center text-green-600 hover:text-green-700 transition-colors">
              <HiOutlineMail className="w-5 h-5 mr-2" />
              Email Us
            </a>
            <button className="flex items-center text-green-600 hover:text-green-700 transition-colors">
              <HiOutlineChat className="w-5 h-5 mr-2" />
              Open Chat
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
